.variableList {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  height: 160px;
  overflow-y: auto;
}

.checkboxStyles::part(base) {
  width: 100%;
  padding: 8px;
  border-bottom: var(--mo-color-secondary-30) 1px solid;
}

.formStyles {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.twoInputsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--mo-spacing-x-small);
}

.inputWrapper {
  flex: 1;
}

.dialogHeader {
  font-family: var(--mo-font-sans);
  font-size: var(--font-size-2xl);
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 125% */
  border-bottom: 2px solid #d9d9d9;
  padding-bottom: 10px;
}

.dialogFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
