.ag-theme-modes {
  -webkit-font-smoothing: antialiased;

  /** General */
  --ag-foreground-color: var(--mo-color-primary-7);
  --ag-background-color: var(--mo-color-primary-100);
  --ag-secondary-background-color: var(
    --mo-color-primary-90,
    var(--mo-color-secondary-90)
  );
  --ag-alpine-active-color: var(
    --mo-color-primary-60,
    var(--mo-color-secondary-30)
  );
  --ag-header-cell-moving-background-color: var(
    --mo-color-primary-90,
    var(--mo-color-secondary-90)
  );

  /** Rows */
  --ag-odd-row-background-color: var(--mo-color-primary-100);
  --ag-selected-row-background-color: var(
    --mo-color-primary-70,
    var(--mo-color-secondary-70)
  );
  --ag-row-hover-color: transparent;

  /** Cells */
  --ag-cell-background-color-hover: var(
    --mo-color-primary-90,
    var(--mo-color-secondary-90)
  );
  --ag-cell-border-color: var(--mo-color-secondary-70);
  --ag-cell-highlight-color: var(
    --mo-color-primary-20,
    var(--mo-color-primary-7)
  );

  /** Header */
  --ag-header-background-color: var(--mo-color-primary-100);
  --ag-header-foreground-color: var(--mo-color-primary-7);
  --ag-header-column-resize-handle-color: var(--mo-color-secondary-30);

  /** Borders */
  --ag-border-color: transparent;
  --ag-row-border-color: var(--mo-color-secondary-70);

  /** Misc */
  --ag-checkbox-checked-color: var(
    --mo-color-primary-20,
    var(--mo-color-primary-7)
  );
  --ag-checkbox-indeterminate-color: var(
    --mo-color-primary-70,
    var(--mo-color-secondary-70)
  );
  --ag-modal-overlay-background-color: var(--mo-overlay-background-color);

  color: var(--ag-foreground-color, #000);
  font-family: var(--mo-font-sans);
  font-weight: var(--mo-font-weight-normal);
  font-size: var(--mo-font-size-medium);
  letter-spacing: 0.3px;
  line-height: normal;
}

.ag-header-row-column-filter {
  border-top: 1px solid var(--mo-color-secondary-70) !important;
}

.ag-cell:hover {
  background-color: var(--ag-cell-background-color-hover);
  box-shadow: inset 0 -1px 0 var(--ag-cell-highlight-color);
}

.ag-cell {
  border: none !important;
  display: flex;
  align-items: center;
  border-right: 1px solid var(--ag-cell-border-color) !important;
}

.ag-pinned-left-cols-container {
  border-right: 1px solid var(--mo-color-primary-7);
  border-left: 1px solid var(--mo-color-secondary-70);
}
.ag-header-cell-text {
  line-height: 20px;
}
.ag-body {
  border-bottom: 1px solid var(--mo-color-secondary-70);
}
.ag-center-cols-clipper {
  border-left: 1px solid var(--mo-color-secondary-70);
  border-top: none;
}

.ag-input-field-input {
  font-family: var(--mo-font-sans);
  font-weight: var(--mo-font-weight-normal);
}

.ag-checkbox-input {
  border-radius: 0 !important;
}

.ag-filter-apply-panel-button {
  border-radius: 0 !important;
  background-color: var(
    --mo-color-primary-30,
    var(--mo-color-primary-7)
  ) !important;
  color: var(--mo-color-primary-100) !important;
  font-family: var(--mo-font-sans);
  font-weight: var(--mo-font-weight-normal);
  letter-spacing: var(--mo-letter-spacing-normal);
  border: none !important;
  line-height: var(--mo-button-line-height-small) !important;
  padding: 6px var(--mo-spacing-medium) !important;
}

.ag-filter-apply-panel-button:focus-visible,
.ag-filter-apply-panel-button:focus {
  outline: var(--size-xxs) solid var(--mo-color-primary-7) !important;
  outline-offset: var(--size-xxs) !important;
  box-shadow: none !important;
}

.ag-filter-apply-panel-button:first-of-type {
  background-color: var(--mo-color-primary-100) !important;
  color: var(--mo-color-primary-7) !important;
  border: 2px solid var(--mo-color-primary-7) !important;
}

.ag-filter-apply-panel-button:first-of-type {
  background-color: var(--mo-color-primary-100) !important;
  color: var(--mo-color-primary-7) !important;
  border: var(--mo-size-2x-small) solid var(--mo-color-primary-7) !important;
}

.ag-filter-apply-panel-button:first-of-type:hover {
  background-color: var(--mo-color-secondary-90) !important;
  transition: 0.1s all ease-out;
}

.ag-filter-apply-panel-button:first-of-type:active {
  background-color: var(--mo-color-secondary-70) !important;
  transition: 0.05s all ease-out;
}

.ag-filter-apply-panel-button:hover {
  background-color: var(--mo-color-secondary-30) !important;
  transition: 0.1s all ease-out;
  cursor: pointer;
}

.ag-filter-apply-panel-button:active {
  background-color: var(--mo-color-secondary-70) !important;
  color: var(--mo-color-primary-7) !important;
  transition: 0.05s all ease-out;
}

.ag-number-field-input {
  border: 1px solid var(--mo-color-primary-7) !important;
  border-radius: 0 !important;
}

.ag-header-cell-resize:last-of-type {
  display: none;
}

.ag-theme-modes .ag-icon {
  font-family: "agGridAlpine";
  font-size: 16px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ag-icon {
  font-family: "modes-icons";
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

/** Icon definitions, most are still ag-grid defaults as Modes is missing some icons in correct format. */

.ag-sort-indicator-icon {
  font-family: "modes-icons";
}

.ag-theme-modes .ag-icon-aggregation::before {
  font-family: "agGridAlpine";
  content: "\\f101";
}
.ag-theme-modes .ag-icon-arrows::before {
  font-family: "modes-icons";
  content: "drag-handle-alt";
}
.ag-theme-modes .ag-icon-asc::before {
  font-family: "modes-icons";
  content: "sort-asc-alphabetic";
}
.ag-theme-modes .ag-icon-cancel::before {
  font-family: "modes-icons";
  content: "cross-circle-fill";
}
.ag-theme-modes .ag-icon-chart::before {
  font-family: "agGridAlpine";
  content: "\\f105";
}
.ag-theme-modes .ag-icon-color-picker::before {
  font-family: "modes-icons";
  content: "colors";
}
.ag-theme-modes .ag-icon-columns::before {
  font-family: "agGridAlpine";
  content: "\\f10a";
}
.ag-theme-modes .ag-icon-contracted::before {
  font-family: "modes-icons";
  content: "chevron-right";
}
.ag-theme-modes .ag-icon-copy::before {
  font-family: "modes-icons";
  content: "copy";
}
.ag-theme-modes .ag-icon-cross::before {
  font-family: "modes-icons";
  content: "cross";
}
.ag-theme-modes .ag-icon-csv::before {
  font-family: "modes-icons";
  content: "word";
}
.ag-theme-modes .ag-icon-desc::before {
  font-family: "modes-icons";
  content: "sort-desc-alphabetic";
}
.ag-theme-modes .ag-icon-excel::before {
  font-family: "modes-icons";
  content: "word";
}
.ag-theme-modes .ag-icon-expanded::before {
  font-family: "modes-icons";
  content: "chevron-left";
}
.ag-theme-modes .ag-icon-eye-slash::before {
  font-family: "modes-icons";
  content: "eye-closed";
}
.ag-theme-modes .ag-icon-eye::before {
  font-family: "modes-icons";
  content: "eye";
}
.ag-theme-modes .ag-floating-filter-button .ag-icon-filter::before {
  font-family: "modes-icons";
  content: "filter-alt";
}
.ag-theme-modes .ag-header-icon .ag-icon-filter::before {
  font-family: "modes-icons";
  content: "filter";
}
.ag-theme-modes .ag-icon-first::before {
  font-family: "modes-icons";
  content: "first-page";
}
.ag-theme-modes .ag-icon-grip::before {
  font-family: "modes-icons";
  content: "drag-handle";
}
.ag-theme-modes .ag-icon-group::before {
  font-family: "modes-icons";
  content: "list";
}
.ag-theme-modes .ag-icon-last::before {
  font-family: "modes-icons";
  content: "last-page";
}
.ag-theme-modes .ag-icon-left::before {
  font-family: "modes-icons";
  content: "arrow-left";
}
.ag-theme-modes .ag-icon-linked::before {
  font-family: "modes-icons";
  content: "link-connected";
}
.ag-theme-modes .ag-icon-loading::before {
  font-family: "modes-icons";
  content: "loading";
}
.ag-theme-modes .ag-icon-maximize::before {
  font-family: "modes-icons";
  content: "maximize";
}
.ag-theme-modes .ag-icon-menu::before {
  font-family: "modes-icons";
  font-size: 20px;
  content: "more";
}
.ag-theme-modes .ag-icon-minimize::before {
  font-family: "modes-icons";
  content: "minimize";
}
.ag-theme-modes .ag-icon-next::before {
  font-family: "modes-icons";
  content: "chevron-right";
}
.ag-theme-modes .ag-icon-none::before {
  font-family: "agGridAlpine";
  content: "\\f120";
}
.ag-theme-modes .ag-icon-not-allowed::before {
  font-family: "modes-icons";
  content: "blocked";
}
.ag-theme-modes .ag-icon-paste::before {
  font-family: "agGridAlpine";
  content: "\\f122";
}
.ag-theme-modes .ag-icon-pin::before {
  font-family: "agGridAlpine";
  content: "\\f123";
}
.ag-theme-modes .ag-icon-pivot::before {
  font-family: "agGridAlpine";
  content: "\\f124";
}
.ag-theme-modes .ag-icon-previous::before {
  font-family: "modes-icons";
  content: "chevron-left";
}
.ag-theme-modes .ag-icon-right::before {
  font-family: "modes-icons";
  content: "chevron-right";
}
.ag-theme-modes .ag-icon-save::before {
  font-family: "modes-icons";
  content: "save";
}
.ag-theme-modes .ag-icon-small-down::before {
  font-family: "modes-icons";
  content: "chevron-down";
}
.ag-theme-modes .ag-icon-small-left::before {
  font-family: "modes-icons";
  content: "chevron-left";
}
.ag-theme-modes .ag-icon-small-right::before {
  font-family: "modes-icons";
  content: "chevron-right";
}
.ag-theme-modes .ag-icon-small-up::before {
  font-family: "modes-icons";
  content: "chevron-up";
}
.ag-theme-modes .ag-icon-tick::before {
  font-family: "modes-icons";
  content: "check";
}
.ag-theme-modes .ag-icon-tree-closed::before {
  font-family: "modes-icons";
  content: "chevron-right";
}
.ag-theme-modes .ag-icon-tree-indeterminate::before {
  font-family: "modes-icons";
  content: "minus";
}
.ag-theme-modes .ag-icon-tree-open::before {
  font-family: "modes-icons";
  content: "chevron-down";
}
.ag-theme-modes .ag-icon-unlinked::before {
  font-family: "modes-icons";
  content: "link-disconnected";
}
.ag-theme-modes .ag-icon-row-drag::before {
  font-family: "modes-icons";
  content: "drag-handle";
}
.ag-theme-modes .ag-left-arrow::before {
  font-family: "modes-icons";
  content: "arrow-left";
}
.ag-theme-modes .ag-right-arrow::before {
  font-family: "modes-icons";
  content: "arrow-right";
}

.ag-theme-modes .ag-root-wrapper,
.ag-theme-modes .ag-sticky-top {
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
}
.ag-theme-modes [class^="ag-"],
.ag-theme-modes [class^="ag-"]:focus,
.ag-theme-modes [class^="ag-"]:after,
.ag-theme-modes [class^="ag-"]:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}
.ag-theme-modes [class^="ag-"]::-ms-clear {
  display: none;
}
.ag-theme-modes .ag-checkbox .ag-input-wrapper,
.ag-theme-modes .ag-radio-button .ag-input-wrapper {
  overflow: visible;
}
.ag-theme-modes .ag-range-field .ag-input-wrapper {
  height: 100%;
}
.ag-theme-modes .ag-toggle-button {
  -webkit-box-flex: 0;
  flex: none;
  width: unset;
  min-width: unset;
}
.ag-theme-modes .ag-ltr .ag-label-align-right .ag-label {
  margin-left: 6px;
}

.ag-theme-modes .ag-rtl .ag-label-align-right .ag-label {
  margin-right: 6px;
}

.ag-theme-modes input[class^="ag-"] {
  margin: 0;
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
}
.ag-theme-modes textarea[class^="ag-"],
.ag-theme-modes select[class^="ag-"] {
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
}
.ag-theme-modes input[class^="ag-"]:not([type]),
.ag-theme-modes input[class^="ag-"][type="text"],
.ag-theme-modes input[class^="ag-"][type="number"],
.ag-theme-modes input[class^="ag-"][type="tel"],
.ag-theme-modes input[class^="ag-"][type="date"],
.ag-theme-modes input[class^="ag-"][type="datetime-local"],
.ag-theme-modes textarea[class^="ag-"] {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  border-width: 1px;
  border-style: solid;
  border-color: #babfc7;
  border-color: var(--ag-input-border-color, var(--ag-border-color, #babfc7));
}
.ag-theme-modes input[class^="ag-"]:not([type]):disabled,
.ag-theme-modes input[class^="ag-"][type="text"]:disabled,
.ag-theme-modes input[class^="ag-"][type="number"]:disabled,
.ag-theme-modes input[class^="ag-"][type="tel"]:disabled,
.ag-theme-modes input[class^="ag-"][type="date"]:disabled,
.ag-theme-modes input[class^="ag-"][type="datetime-local"]:disabled,
.ag-theme-modes textarea[class^="ag-"]:disabled {
  color: rgba(24, 29, 31, 0.5);
  color: var(--ag-disabled-foreground-color, rgba(24, 29, 31, 0.5));
  background-color: #f1f2f4;
  background-color: var(--ag-input-disabled-background-color, #f1f2f4);
  border-color: rgba(186, 191, 199, 0.3);
  border-color: var(--ag-input-disabled-border-color, rgba(186, 191, 199, 0.3));
}
.ag-theme-modes input[class^="ag-"]:not([type]):focus,
.ag-theme-modes input[class^="ag-"][type="text"]:focus,
.ag-theme-modes input[class^="ag-"][type="number"]:focus,
.ag-theme-modes input[class^="ag-"][type="tel"]:focus,
.ag-theme-modes input[class^="ag-"][type="date"]:focus,
.ag-theme-modes input[class^="ag-"][type="datetime-local"]:focus,
.ag-theme-modes textarea[class^="ag-"]:focus {
  outline: none;
  -webkit-box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
  box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
  border-color: rgba(33, 150, 243, 0.4);
  border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes input[class^="ag-"]:not([type]):invalid,
.ag-theme-modes input[class^="ag-"][type="text"]:invalid,
.ag-theme-modes input[class^="ag-"][type="number"]:invalid,
.ag-theme-modes input[class^="ag-"][type="tel"]:invalid,
.ag-theme-modes input[class^="ag-"][type="date"]:invalid,
.ag-theme-modes input[class^="ag-"][type="datetime-local"]:invalid,
.ag-theme-modes textarea[class^="ag-"]:invalid {
  border-width: 2px;
  border-style: solid;
  border-color: #e02525;
  border-color: var(
    --ag-input-border-color-invalid,
    var(--ag-invalid-color, #e02525)
  );
}
.ag-theme-modes input[class^="ag-"][type="number"] {
  -moz-appearance: textfield;
}
.ag-theme-modes input[class^="ag-"][type="number"]::-webkit-outer-spin-button,
.ag-theme-modes input[class^="ag-"][type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ag-theme-modes input[class^="ag-"][type="range"] {
  padding: 0;
}
.ag-theme-modes input[class^="ag-"][type="button"]:focus,
.ag-theme-modes button[class^="ag-"]:focus {
  -webkit-box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
  box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
}
.ag-theme-modes .ag-drag-handle {
  color: #181d1f;
  color: var(
    --ag-secondary-foreground-color,
    var(--ag-foreground-color, #181d1f)
  );
}
.ag-theme-modes .ag-list-item,
.ag-theme-modes .ag-virtual-list-item {
  height: 24px;
}
.ag-theme-modes .ag-keyboard-focus .ag-virtual-list-item:focus {
  outline: none;
}
.ag-theme-modes .ag-keyboard-focus .ag-virtual-list-item:focus::after {
  content: "";
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 4px;
  left: 4px;
  display: block;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid;
  border-color: rgba(33, 150, 243, 0.4);
  border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes .ag-select-list {
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
  overflow-y: auto;
  overflow-x: hidden;
}
.ag-theme-modes .ag-list-item {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ag-theme-modes .ag-list-item.ag-active-item {
  background-color: rgba(33, 150, 243, 0.1);
  background-color: var(--ag-row-hover-color, rgba(33, 150, 243, 0.1));
}
.ag-theme-modes .ag-select-list-item {
  padding-left: 4px;
  padding-right: 4px;
  cursor: default;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.ag-theme-modes .ag-select-list-item span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ag-theme-modes .ag-select .ag-picker-field-wrapper {
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
  min-height: 24px;
  cursor: default;
}
.ag-theme-modes .ag-select.ag-disabled .ag-picker-field-wrapper:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ag-theme-modes .ag-select:not(.ag-cell-editor) {
  height: 32px;
}
.ag-theme-modes .ag-select .ag-picker-field-display {
  margin: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ag-theme-modes .ag-select .ag-picker-field-icon {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.ag-theme-modes .ag-select.ag-disabled {
  opacity: 0.5;
}
.ag-theme-modes .ag-rich-select {
  background-color: #f8f8f8;
  background-color: var(--ag-control-panel-background-color, #f8f8f8);
}
.ag-theme-modes .ag-rich-select-list {
  width: 100%;
  min-width: 200px;
  height: 273px;
}
.ag-theme-modes .ag-rich-select-value {
  padding: 0 6px 0 18px;
  height: 32px;
  border-bottom: solid 1px;
  border-bottom-color: #dde2eb;
  border-bottom-color: var(--ag-secondary-border-color, #dde2eb);
}
.ag-theme-modes .ag-rich-select-virtual-list-item {
  cursor: default;
  height: 24px;
}
.ag-keyboard-focus
  .ag-theme-modes
  .ag-rich-select-virtual-list-item:focus::after {
  content: none;
}
.ag-theme-modes .ag-rich-select-virtual-list-item:hover {
  background-color: rgba(33, 150, 243, 0.1);
  background-color: var(--ag-row-hover-color, rgba(33, 150, 243, 0.1));
}
.ag-theme-modes .ag-rich-select-row {
  padding-left: 12px;
}
.ag-theme-modes .ag-rich-select-row-selected {
  background-color: rgba(33, 150, 243, 0.3);
  background-color: var(
    --ag-selected-row-background-color,
    rgba(33, 150, 243, 0.3)
  );
}
.ag-theme-modes .ag-row-drag,
.ag-theme-modes .ag-selection-checkbox,
.ag-theme-modes .ag-group-expanded,
.ag-theme-modes .ag-group-contracted {
  color: #181d1f;
  color: var(
    --ag-secondary-foreground-color,
    var(--ag-foreground-color, #181d1f)
  );
}
.ag-theme-modes .ag-ltr .ag-row-drag,
.ag-theme-modes .ag-ltr .ag-selection-checkbox,
.ag-theme-modes .ag-ltr .ag-group-expanded,
.ag-theme-modes .ag-ltr .ag-group-contracted {
  margin-right: 12px;
}

.ag-theme-modes .ag-rtl .ag-row-drag,
.ag-theme-modes .ag-rtl .ag-selection-checkbox,
.ag-theme-modes .ag-rtl .ag-group-expanded,
.ag-theme-modes .ag-rtl .ag-group-contracted {
  margin-left: 12px;
}

.ag-theme-modes .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
  height: min(var(--ag-line-height, 40px), 40px);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  flex: none;
}
.ag-theme-modes .ag-group-expanded,
.ag-theme-modes .ag-group-contracted {
  cursor: pointer;
}
.ag-theme-modes .ag-group-title-bar-icon {
  cursor: pointer;
  -webkit-box-flex: 0;
  flex: none;
  color: #181d1f;
  color: var(
    --ag-secondary-foreground-color,
    var(--ag-foreground-color, #181d1f)
  );
}
.ag-theme-modes .ag-ltr .ag-group-child-count {
  margin-left: 2px;
}

.ag-theme-modes .ag-rtl .ag-group-child-count {
  margin-right: 2px;
}

.ag-theme-modes .ag-group-title-bar {
  background-color: #fff;
  background-color: var(--ag-subheader-background-color, #fff);
  padding: 6px;
}
.ag-theme-modes .ag-group-toolbar {
  padding: 6px;
}
.ag-theme-modes .ag-disabled-group-title-bar,
.ag-theme-modes .ag-disabled-group-container {
  opacity: 0.5;
}
.ag-theme-modes .group-item {
  margin: 3px 0;
}
.ag-theme-modes .ag-label {
  white-space: nowrap;
}
.ag-theme-modes .ag-ltr .ag-label {
  margin-right: 6px;
}

.ag-theme-modes .ag-rtl .ag-label {
  margin-left: 6px;
}

.ag-theme-modes .ag-label-align-top .ag-label {
  margin-bottom: 3px;
}
.ag-theme-modes .ag-angle-select[disabled] {
  color: rgba(24, 29, 31, 0.5);
  color: var(--ag-disabled-foreground-color, rgba(24, 29, 31, 0.5));
  pointer-events: none;
}
.ag-theme-modes .ag-angle-select[disabled] .ag-angle-select-field {
  opacity: 0.4;
}
.ag-theme-modes .ag-ltr .ag-slider-field,
.ag-theme-modes .ag-ltr .ag-angle-select-field {
  margin-right: 12px;
}

.ag-theme-modes .ag-rtl .ag-slider-field,
.ag-theme-modes .ag-rtl .ag-angle-select-field {
  margin-left: 12px;
}

.ag-theme-modes .ag-angle-select-parent-circle {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: solid 1px;
  border-color: #babfc7;
  border-color: var(--ag-border-color, #babfc7);
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
}
.ag-theme-modes .ag-angle-select-child-circle {
  top: 4px;
  left: 12px;
  width: 6px;
  height: 6px;
  margin-left: -3px;
  margin-top: -4px;
  border-radius: 3px;
  background-color: #181d1f;
  background-color: var(
    --ag-secondary-foreground-color,
    var(--ag-foreground-color, #181d1f)
  );
}
.ag-theme-modes .ag-picker-field-wrapper {
  border: 1px solid;
  border-color: var(--mo-color-primary-7);
}
.ag-select-list-item:not(:last-of-type) {
  border-bottom: 1px solid var(--mo-color-secondary-70);
}
.ag-select-list-item:hover {
  background-color: var(--mo-color-secondary-90) !important;
}
.ag-theme-modes .ag-picker-field-button {
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
  color: #181d1f;
  color: var(
    --ag-secondary-foreground-color,
    var(--ag-foreground-color, #181d1f)
  );
}
.ag-theme-modes .ag-dialog.ag-color-dialog {
  border-radius: 5px;
}
.ag-theme-modes .ag-color-picker .ag-picker-field-display {
  height: 16px;
}
.ag-theme-modes .ag-color-panel {
  padding: 6px;
}
.ag-theme-modes .ag-spectrum-color {
  background-color: rgb(255, 0, 0);
  border-radius: 2px;
}
.ag-theme-modes .ag-spectrum-tools {
  padding: 10px;
}
.ag-theme-modes .ag-spectrum-sat {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(white),
    to(rgba(204, 154, 129, 0))
  );
  background-image: linear-gradient(to right, white, rgba(204, 154, 129, 0));
}
.ag-theme-modes .ag-spectrum-val {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(black),
    to(rgba(204, 154, 129, 0))
  );
  background-image: linear-gradient(to top, black, rgba(204, 154, 129, 0));
}
.ag-theme-modes .ag-spectrum-dragger {
  border-radius: 12px;
  height: 12px;
  width: 12px;
  border: 1px solid white;
  background: black;
  -webkit-box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.24);
}
.ag-theme-modes .ag-spectrum-hue-background {
  border-radius: 2px;
}
.ag-theme-modes .ag-spectrum-alpha-background {
  border-radius: 2px;
}
.ag-theme-modes .ag-spectrum-tool {
  margin-bottom: 10px;
  height: 12px;
  border-radius: 2px;
}
.ag-theme-modes .ag-spectrum-slider {
  margin-top: -12px;
  width: 13px;
  height: 13px;
  border-radius: 13px;
  background-color: rgb(248, 248, 248);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
}
.ag-theme-modes .ag-recent-color {
  margin: 0 3px;
}
.ag-theme-modes .ag-recent-color:first-child {
  margin-left: 0;
}
.ag-theme-modes .ag-recent-color:last-child {
  margin-right: 0;
}
.ag-theme-modes.ag-dnd-ghost {
  border: solid 1px;
  border-color: var(--mo-color-primary-7);
  background: var(--ag-background-color, #fff);
  -webkit-box-shadow: var(--mo-elevation-4);
  box-shadow: var(--mo-elevation-4);
  border-radius: 0 !important;
  padding: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #181d1f;
  color: var(
    --ag-secondary-foreground-color,
    var(--ag-foreground-color, #181d1f)
  );
  height: 40px !important;
  line-height: 40px;
  margin: 0;
  padding: 0 12px;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  font-family: var(--mo-font-sans);
  font-weight: var(--mo-font-weight-bold);
}
.ag-theme-modes .ag-dnd-ghost-icon {
  margin-right: 6px;
  color: #181d1f;
  color: var(--ag-foreground-color, #181d1f);
}
.ag-theme-modes .ag-popup-child:not(.ag-tooltip-custom) {
  -webkit-box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
  box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
}
.ag-dragging-range-handle .ag-theme-modes .ag-dialog,
.ag-dragging-fill-handle .ag-theme-modes .ag-dialog {
  opacity: 0.7;
  pointer-events: none;
}
.ag-theme-modes .ag-dialog {
  border-radius: 3px;
  border: solid 1px;
  border-color: #babfc7;
  border-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-panel {
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
}
.ag-theme-modes .ag-panel-title-bar {
  background-color: #f8f8f8;
  background-color: var(--ag-header-background-color, #f8f8f8);
  color: #181d1f;
  color: var(
    --ag-header-foreground-color,
    var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f))
  );
  height: 48px;
  padding: 6px 18px;
  border-bottom: solid 1px;
  border-bottom-color: #babfc7;
  border-bottom-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-ltr .ag-panel-title-bar-button {
  margin-left: 6px;
}

.ag-theme-modes .ag-rtl .ag-panel-title-bar-button {
  margin-right: 6px;
}

.ag-theme-modes .ag-tooltip {
  background-color: #f8f8f8;
  background-color: var(--ag-header-background-color, #f8f8f8);
  color: #181d1f;
  color: var(--ag-foreground-color, #181d1f);
  padding: 6px;
  border: solid 1px;
  border-color: #babfc7;
  border-color: var(--ag-border-color, #babfc7);
  border-radius: 3px;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  white-space: normal;
}
.ag-theme-modes .ag-tooltip.ag-tooltip-hiding {
  opacity: 0;
}
.ag-theme-modes .ag-tooltip-custom {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.ag-theme-modes .ag-tooltip-custom.ag-tooltip-hiding {
  opacity: 0;
}
.ag-theme-modes .ag-ltr .ag-column-select-indent-1 {
  padding-left: 16px;
}

.ag-theme-modes .ag-rtl .ag-column-select-indent-1 {
  padding-right: 16px;
}

.ag-theme-modes .ag-ltr .ag-column-select-indent-2 {
  padding-left: 32px;
}

.ag-theme-modes .ag-rtl .ag-column-select-indent-2 {
  padding-right: 32px;
}

.ag-theme-modes .ag-ltr .ag-column-select-indent-3 {
  padding-left: 48px;
}

.ag-theme-modes .ag-rtl .ag-column-select-indent-3 {
  padding-right: 48px;
}

.ag-theme-modes .ag-ltr .ag-column-select-indent-4 {
  padding-left: 64px;
}

.ag-theme-modes .ag-rtl .ag-column-select-indent-4 {
  padding-right: 64px;
}

.ag-theme-modes .ag-ltr .ag-column-select-indent-5 {
  padding-left: 80px;
}

.ag-theme-modes .ag-rtl .ag-column-select-indent-5 {
  padding-right: 80px;
}

.ag-theme-modes .ag-ltr .ag-column-select-indent-6 {
  padding-left: 96px;
}

.ag-theme-modes .ag-rtl .ag-column-select-indent-6 {
  padding-right: 96px;
}

.ag-theme-modes .ag-ltr .ag-column-select-indent-7 {
  padding-left: 112px;
}

.ag-theme-modes .ag-rtl .ag-column-select-indent-7 {
  padding-right: 112px;
}

.ag-theme-modes .ag-ltr .ag-column-select-indent-8 {
  padding-left: 128px;
}

.ag-theme-modes .ag-rtl .ag-column-select-indent-8 {
  padding-right: 128px;
}

.ag-theme-modes .ag-ltr .ag-column-select-indent-9 {
  padding-left: 144px;
}

.ag-theme-modes .ag-rtl .ag-column-select-indent-9 {
  padding-right: 144px;
}

.ag-theme-modes .ag-column-select-header-icon {
  cursor: pointer;
}
.ag-theme-modes .ag-keyboard-focus .ag-column-select-header-icon:focus {
  outline: none;
}
.ag-theme-modes .ag-keyboard-focus .ag-column-select-header-icon:focus::after {
  content: "";
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 0px;
  left: 0px;
  display: block;
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  border: 1px solid;
  border-color: rgba(33, 150, 243, 0.4);
  border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes .ag-ltr .ag-column-group-icons:not(:last-child),
.ag-theme-modes .ag-ltr .ag-column-select-header-icon:not(:last-child),
.ag-theme-modes .ag-ltr .ag-column-select-header-checkbox:not(:last-child),
.ag-theme-modes
  .ag-ltr
  .ag-column-select-header-filter-wrapper:not(:last-child),
.ag-theme-modes .ag-ltr .ag-column-select-checkbox:not(:last-child),
.ag-theme-modes .ag-ltr .ag-column-select-column-drag-handle:not(:last-child),
.ag-theme-modes
  .ag-ltr
  .ag-column-select-column-group-drag-handle:not(:last-child),
.ag-theme-modes .ag-ltr .ag-column-select-column-label:not(:last-child) {
  margin-right: 12px;
}

.ag-theme-modes .ag-rtl .ag-column-group-icons:not(:last-child),
.ag-theme-modes .ag-rtl .ag-column-select-header-icon:not(:last-child),
.ag-theme-modes .ag-rtl .ag-column-select-header-checkbox:not(:last-child),
.ag-theme-modes
  .ag-rtl
  .ag-column-select-header-filter-wrapper:not(:last-child),
.ag-theme-modes .ag-rtl .ag-column-select-checkbox:not(:last-child),
.ag-theme-modes .ag-rtl .ag-column-select-column-drag-handle:not(:last-child),
.ag-theme-modes
  .ag-rtl
  .ag-column-select-column-group-drag-handle:not(:last-child),
.ag-theme-modes .ag-rtl .ag-column-select-column-label:not(:last-child) {
  margin-left: 12px;
}

.ag-theme-modes .ag-keyboard-focus .ag-column-select-virtual-list-item:focus {
  outline: none;
}
.ag-theme-modes
  .ag-keyboard-focus
  .ag-column-select-virtual-list-item:focus::after {
  content: "";
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 1px;
  left: 1px;
  display: block;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border: 1px solid;
  border-color: rgba(33, 150, 243, 0.4);
  border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes .ag-column-select-column-group:not(:last-child),
.ag-theme-modes .ag-column-select-column:not(:last-child) {
  margin-bottom: 9px;
}
.ag-theme-modes .ag-column-select-column-readonly,
.ag-theme-modes .ag-column-select-column-group-readonly {
  color: rgba(24, 29, 31, 0.5);
  color: var(--ag-disabled-foreground-color, rgba(24, 29, 31, 0.5));
  pointer-events: none;
}
.ag-theme-modes .ag-ltr .ag-column-select-add-group-indent {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-column-select-add-group-indent {
  margin-right: 28px;
}

.ag-theme-modes .ag-column-select-virtual-list-viewport {
  padding: 6px 0px;
}
.ag-theme-modes .ag-column-select-virtual-list-item {
  padding: 0 12px;
}
.ag-theme-modes .ag-rtl {
  text-align: right;
}
.ag-theme-modes .ag-root-wrapper {
  border: solid 1px;
  border-color: #babfc7;
  border-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-1 {
  padding-left: 46px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-1 {
  padding-right: 46px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-1 {
  padding-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-1 {
  padding-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row-level-1 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-1 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-2 {
  padding-left: 74px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-2 {
  padding-right: 74px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-2 {
  padding-left: 56px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-2 {
  padding-right: 56px;
}

.ag-theme-modes .ag-ltr .ag-row-level-2 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-2 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-3 {
  padding-left: 102px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-3 {
  padding-right: 102px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-3 {
  padding-left: 84px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-3 {
  padding-right: 84px;
}

.ag-theme-modes .ag-ltr .ag-row-level-3 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-3 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-4 {
  padding-left: 130px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-4 {
  padding-right: 130px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-4 {
  padding-left: 112px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-4 {
  padding-right: 112px;
}

.ag-theme-modes .ag-ltr .ag-row-level-4 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-4 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-5 {
  padding-left: 158px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-5 {
  padding-right: 158px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-5 {
  padding-left: 140px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-5 {
  padding-right: 140px;
}

.ag-theme-modes .ag-ltr .ag-row-level-5 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-5 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-6 {
  padding-left: 186px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-6 {
  padding-right: 186px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-6 {
  padding-left: 168px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-6 {
  padding-right: 168px;
}

.ag-theme-modes .ag-ltr .ag-row-level-6 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-6 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-7 {
  padding-left: 214px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-7 {
  padding-right: 214px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-7 {
  padding-left: 196px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-7 {
  padding-right: 196px;
}

.ag-theme-modes .ag-ltr .ag-row-level-7 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-7 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-8 {
  padding-left: 242px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-8 {
  padding-right: 242px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-8 {
  padding-left: 224px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-8 {
  padding-right: 224px;
}

.ag-theme-modes .ag-ltr .ag-row-level-8 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-8 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-9 {
  padding-left: 270px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-9 {
  padding-right: 270px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-9 {
  padding-left: 252px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-9 {
  padding-right: 252px;
}

.ag-theme-modes .ag-ltr .ag-row-level-9 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-9 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-10 {
  padding-left: 298px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-10 {
  padding-right: 298px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-10 {
  padding-left: 280px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-10 {
  padding-right: 280px;
}

.ag-theme-modes .ag-ltr .ag-row-level-10 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-10 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-11 {
  padding-left: 326px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-11 {
  padding-right: 326px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-11 {
  padding-left: 308px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-11 {
  padding-right: 308px;
}

.ag-theme-modes .ag-ltr .ag-row-level-11 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-11 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-12 {
  padding-left: 354px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-12 {
  padding-right: 354px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-12 {
  padding-left: 336px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-12 {
  padding-right: 336px;
}

.ag-theme-modes .ag-ltr .ag-row-level-12 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-12 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-13 {
  padding-left: 382px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-13 {
  padding-right: 382px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-13 {
  padding-left: 364px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-13 {
  padding-right: 364px;
}

.ag-theme-modes .ag-ltr .ag-row-level-13 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-13 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-14 {
  padding-left: 410px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-14 {
  padding-right: 410px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-14 {
  padding-left: 392px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-14 {
  padding-right: 392px;
}

.ag-theme-modes .ag-ltr .ag-row-level-14 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-14 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-15 {
  padding-left: 438px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-15 {
  padding-right: 438px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-15 {
  padding-left: 420px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-15 {
  padding-right: 420px;
}

.ag-theme-modes .ag-ltr .ag-row-level-15 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-15 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-16 {
  padding-left: 466px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-16 {
  padding-right: 466px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-16 {
  padding-left: 448px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-16 {
  padding-right: 448px;
}

.ag-theme-modes .ag-ltr .ag-row-level-16 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-16 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-17 {
  padding-left: 494px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-17 {
  padding-right: 494px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-17 {
  padding-left: 476px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-17 {
  padding-right: 476px;
}

.ag-theme-modes .ag-ltr .ag-row-level-17 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-17 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-18 {
  padding-left: 522px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-18 {
  padding-right: 522px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-18 {
  padding-left: 504px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-18 {
  padding-right: 504px;
}

.ag-theme-modes .ag-ltr .ag-row-level-18 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-18 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-19 {
  padding-left: 550px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-19 {
  padding-right: 550px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-19 {
  padding-left: 532px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-19 {
  padding-right: 532px;
}

.ag-theme-modes .ag-ltr .ag-row-level-19 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-19 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-20 {
  padding-left: 578px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-20 {
  padding-right: 578px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-20 {
  padding-left: 560px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-20 {
  padding-right: 560px;
}

.ag-theme-modes .ag-ltr .ag-row-level-20 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-20 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-21 {
  padding-left: 606px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-21 {
  padding-right: 606px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-21 {
  padding-left: 588px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-21 {
  padding-right: 588px;
}

.ag-theme-modes .ag-ltr .ag-row-level-21 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-21 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-22 {
  padding-left: 634px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-22 {
  padding-right: 634px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-22 {
  padding-left: 616px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-22 {
  padding-right: 616px;
}

.ag-theme-modes .ag-ltr .ag-row-level-22 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-22 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-23 {
  padding-left: 662px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-23 {
  padding-right: 662px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-23 {
  padding-left: 644px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-23 {
  padding-right: 644px;
}

.ag-theme-modes .ag-ltr .ag-row-level-23 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-23 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-24 {
  padding-left: 690px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-24 {
  padding-right: 690px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-24 {
  padding-left: 672px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-24 {
  padding-right: 672px;
}

.ag-theme-modes .ag-ltr .ag-row-level-24 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-24 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-25 {
  padding-left: 718px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-25 {
  padding-right: 718px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-25 {
  padding-left: 700px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-25 {
  padding-right: 700px;
}

.ag-theme-modes .ag-ltr .ag-row-level-25 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-25 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-26 {
  padding-left: 746px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-26 {
  padding-right: 746px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-26 {
  padding-left: 728px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-26 {
  padding-right: 728px;
}

.ag-theme-modes .ag-ltr .ag-row-level-26 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-26 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-27 {
  padding-left: 774px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-27 {
  padding-right: 774px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-27 {
  padding-left: 756px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-27 {
  padding-right: 756px;
}

.ag-theme-modes .ag-ltr .ag-row-level-27 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-27 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-28 {
  padding-left: 802px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-28 {
  padding-right: 802px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-28 {
  padding-left: 784px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-28 {
  padding-right: 784px;
}

.ag-theme-modes .ag-ltr .ag-row-level-28 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-28 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-29 {
  padding-left: 830px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-29 {
  padding-right: 830px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-29 {
  padding-left: 812px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-29 {
  padding-right: 812px;
}

.ag-theme-modes .ag-ltr .ag-row-level-29 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-29 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-30 {
  padding-left: 858px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-30 {
  padding-right: 858px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-30 {
  padding-left: 840px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-30 {
  padding-right: 840px;
}

.ag-theme-modes .ag-ltr .ag-row-level-30 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-30 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-31 {
  padding-left: 886px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-31 {
  padding-right: 886px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-31 {
  padding-left: 868px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-31 {
  padding-right: 868px;
}

.ag-theme-modes .ag-ltr .ag-row-level-31 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-31 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-32 {
  padding-left: 914px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-32 {
  padding-right: 914px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-32 {
  padding-left: 896px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-32 {
  padding-right: 896px;
}

.ag-theme-modes .ag-ltr .ag-row-level-32 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-32 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-33 {
  padding-left: 942px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-33 {
  padding-right: 942px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-33 {
  padding-left: 924px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-33 {
  padding-right: 924px;
}

.ag-theme-modes .ag-ltr .ag-row-level-33 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-33 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-34 {
  padding-left: 970px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-34 {
  padding-right: 970px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-34 {
  padding-left: 952px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-34 {
  padding-right: 952px;
}

.ag-theme-modes .ag-ltr .ag-row-level-34 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-34 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-35 {
  padding-left: 998px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-35 {
  padding-right: 998px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-35 {
  padding-left: 980px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-35 {
  padding-right: 980px;
}

.ag-theme-modes .ag-ltr .ag-row-level-35 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-35 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-36 {
  padding-left: 1026px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-36 {
  padding-right: 1026px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-36 {
  padding-left: 1008px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-36 {
  padding-right: 1008px;
}

.ag-theme-modes .ag-ltr .ag-row-level-36 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-36 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-37 {
  padding-left: 1054px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-37 {
  padding-right: 1054px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-37 {
  padding-left: 1036px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-37 {
  padding-right: 1036px;
}

.ag-theme-modes .ag-ltr .ag-row-level-37 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-37 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-38 {
  padding-left: 1082px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-38 {
  padding-right: 1082px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-38 {
  padding-left: 1064px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-38 {
  padding-right: 1064px;
}

.ag-theme-modes .ag-ltr .ag-row-level-38 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-38 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-39 {
  padding-left: 1110px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-39 {
  padding-right: 1110px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-39 {
  padding-left: 1092px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-39 {
  padding-right: 1092px;
}

.ag-theme-modes .ag-ltr .ag-row-level-39 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-39 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-40 {
  padding-left: 1138px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-40 {
  padding-right: 1138px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-40 {
  padding-left: 1120px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-40 {
  padding-right: 1120px;
}

.ag-theme-modes .ag-ltr .ag-row-level-40 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-40 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-41 {
  padding-left: 1166px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-41 {
  padding-right: 1166px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-41 {
  padding-left: 1148px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-41 {
  padding-right: 1148px;
}

.ag-theme-modes .ag-ltr .ag-row-level-41 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-41 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-42 {
  padding-left: 1194px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-42 {
  padding-right: 1194px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-42 {
  padding-left: 1176px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-42 {
  padding-right: 1176px;
}

.ag-theme-modes .ag-ltr .ag-row-level-42 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-42 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-43 {
  padding-left: 1222px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-43 {
  padding-right: 1222px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-43 {
  padding-left: 1204px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-43 {
  padding-right: 1204px;
}

.ag-theme-modes .ag-ltr .ag-row-level-43 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-43 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-44 {
  padding-left: 1250px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-44 {
  padding-right: 1250px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-44 {
  padding-left: 1232px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-44 {
  padding-right: 1232px;
}

.ag-theme-modes .ag-ltr .ag-row-level-44 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-44 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-45 {
  padding-left: 1278px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-45 {
  padding-right: 1278px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-45 {
  padding-left: 1260px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-45 {
  padding-right: 1260px;
}

.ag-theme-modes .ag-ltr .ag-row-level-45 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-45 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-46 {
  padding-left: 1306px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-46 {
  padding-right: 1306px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-46 {
  padding-left: 1288px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-46 {
  padding-right: 1288px;
}

.ag-theme-modes .ag-ltr .ag-row-level-46 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-46 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-47 {
  padding-left: 1334px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-47 {
  padding-right: 1334px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-47 {
  padding-left: 1316px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-47 {
  padding-right: 1316px;
}

.ag-theme-modes .ag-ltr .ag-row-level-47 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-47 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-48 {
  padding-left: 1362px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-48 {
  padding-right: 1362px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-48 {
  padding-left: 1344px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-48 {
  padding-right: 1344px;
}

.ag-theme-modes .ag-ltr .ag-row-level-48 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-48 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-49 {
  padding-left: 1390px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-49 {
  padding-right: 1390px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-49 {
  padding-left: 1372px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-49 {
  padding-right: 1372px;
}

.ag-theme-modes .ag-ltr .ag-row-level-49 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-49 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-50 {
  padding-left: 1418px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-50 {
  padding-right: 1418px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-50 {
  padding-left: 1400px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-50 {
  padding-right: 1400px;
}

.ag-theme-modes .ag-ltr .ag-row-level-50 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-50 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-51 {
  padding-left: 1446px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-51 {
  padding-right: 1446px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-51 {
  padding-left: 1428px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-51 {
  padding-right: 1428px;
}

.ag-theme-modes .ag-ltr .ag-row-level-51 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-51 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-52 {
  padding-left: 1474px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-52 {
  padding-right: 1474px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-52 {
  padding-left: 1456px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-52 {
  padding-right: 1456px;
}

.ag-theme-modes .ag-ltr .ag-row-level-52 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-52 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-53 {
  padding-left: 1502px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-53 {
  padding-right: 1502px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-53 {
  padding-left: 1484px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-53 {
  padding-right: 1484px;
}

.ag-theme-modes .ag-ltr .ag-row-level-53 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-53 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-54 {
  padding-left: 1530px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-54 {
  padding-right: 1530px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-54 {
  padding-left: 1512px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-54 {
  padding-right: 1512px;
}

.ag-theme-modes .ag-ltr .ag-row-level-54 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-54 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-55 {
  padding-left: 1558px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-55 {
  padding-right: 1558px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-55 {
  padding-left: 1540px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-55 {
  padding-right: 1540px;
}

.ag-theme-modes .ag-ltr .ag-row-level-55 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-55 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-56 {
  padding-left: 1586px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-56 {
  padding-right: 1586px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-56 {
  padding-left: 1568px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-56 {
  padding-right: 1568px;
}

.ag-theme-modes .ag-ltr .ag-row-level-56 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-56 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-57 {
  padding-left: 1614px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-57 {
  padding-right: 1614px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-57 {
  padding-left: 1596px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-57 {
  padding-right: 1596px;
}

.ag-theme-modes .ag-ltr .ag-row-level-57 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-57 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-58 {
  padding-left: 1642px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-58 {
  padding-right: 1642px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-58 {
  padding-left: 1624px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-58 {
  padding-right: 1624px;
}

.ag-theme-modes .ag-ltr .ag-row-level-58 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-58 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-59 {
  padding-left: 1670px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-59 {
  padding-right: 1670px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-59 {
  padding-left: 1652px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-59 {
  padding-right: 1652px;
}

.ag-theme-modes .ag-ltr .ag-row-level-59 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-59 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-60 {
  padding-left: 1698px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-60 {
  padding-right: 1698px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-60 {
  padding-left: 1680px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-60 {
  padding-right: 1680px;
}

.ag-theme-modes .ag-ltr .ag-row-level-60 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-60 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-61 {
  padding-left: 1726px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-61 {
  padding-right: 1726px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-61 {
  padding-left: 1708px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-61 {
  padding-right: 1708px;
}

.ag-theme-modes .ag-ltr .ag-row-level-61 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-61 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-62 {
  padding-left: 1754px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-62 {
  padding-right: 1754px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-62 {
  padding-left: 1736px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-62 {
  padding-right: 1736px;
}

.ag-theme-modes .ag-ltr .ag-row-level-62 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-62 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-63 {
  padding-left: 1782px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-63 {
  padding-right: 1782px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-63 {
  padding-left: 1764px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-63 {
  padding-right: 1764px;
}

.ag-theme-modes .ag-ltr .ag-row-level-63 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-63 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-64 {
  padding-left: 1810px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-64 {
  padding-right: 1810px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-64 {
  padding-left: 1792px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-64 {
  padding-right: 1792px;
}

.ag-theme-modes .ag-ltr .ag-row-level-64 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-64 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-65 {
  padding-left: 1838px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-65 {
  padding-right: 1838px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-65 {
  padding-left: 1820px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-65 {
  padding-right: 1820px;
}

.ag-theme-modes .ag-ltr .ag-row-level-65 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-65 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-66 {
  padding-left: 1866px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-66 {
  padding-right: 1866px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-66 {
  padding-left: 1848px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-66 {
  padding-right: 1848px;
}

.ag-theme-modes .ag-ltr .ag-row-level-66 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-66 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-67 {
  padding-left: 1894px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-67 {
  padding-right: 1894px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-67 {
  padding-left: 1876px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-67 {
  padding-right: 1876px;
}

.ag-theme-modes .ag-ltr .ag-row-level-67 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-67 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-68 {
  padding-left: 1922px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-68 {
  padding-right: 1922px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-68 {
  padding-left: 1904px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-68 {
  padding-right: 1904px;
}

.ag-theme-modes .ag-ltr .ag-row-level-68 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-68 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-69 {
  padding-left: 1950px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-69 {
  padding-right: 1950px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-69 {
  padding-left: 1932px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-69 {
  padding-right: 1932px;
}

.ag-theme-modes .ag-ltr .ag-row-level-69 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-69 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-70 {
  padding-left: 1978px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-70 {
  padding-right: 1978px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-70 {
  padding-left: 1960px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-70 {
  padding-right: 1960px;
}

.ag-theme-modes .ag-ltr .ag-row-level-70 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-70 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-71 {
  padding-left: 2006px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-71 {
  padding-right: 2006px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-71 {
  padding-left: 1988px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-71 {
  padding-right: 1988px;
}

.ag-theme-modes .ag-ltr .ag-row-level-71 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-71 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-72 {
  padding-left: 2034px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-72 {
  padding-right: 2034px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-72 {
  padding-left: 2016px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-72 {
  padding-right: 2016px;
}

.ag-theme-modes .ag-ltr .ag-row-level-72 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-72 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-73 {
  padding-left: 2062px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-73 {
  padding-right: 2062px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-73 {
  padding-left: 2044px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-73 {
  padding-right: 2044px;
}

.ag-theme-modes .ag-ltr .ag-row-level-73 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-73 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-74 {
  padding-left: 2090px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-74 {
  padding-right: 2090px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-74 {
  padding-left: 2072px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-74 {
  padding-right: 2072px;
}

.ag-theme-modes .ag-ltr .ag-row-level-74 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-74 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-75 {
  padding-left: 2118px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-75 {
  padding-right: 2118px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-75 {
  padding-left: 2100px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-75 {
  padding-right: 2100px;
}

.ag-theme-modes .ag-ltr .ag-row-level-75 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-75 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-76 {
  padding-left: 2146px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-76 {
  padding-right: 2146px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-76 {
  padding-left: 2128px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-76 {
  padding-right: 2128px;
}

.ag-theme-modes .ag-ltr .ag-row-level-76 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-76 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-77 {
  padding-left: 2174px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-77 {
  padding-right: 2174px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-77 {
  padding-left: 2156px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-77 {
  padding-right: 2156px;
}

.ag-theme-modes .ag-ltr .ag-row-level-77 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-77 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-78 {
  padding-left: 2202px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-78 {
  padding-right: 2202px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-78 {
  padding-left: 2184px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-78 {
  padding-right: 2184px;
}

.ag-theme-modes .ag-ltr .ag-row-level-78 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-78 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-79 {
  padding-left: 2230px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-79 {
  padding-right: 2230px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-79 {
  padding-left: 2212px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-79 {
  padding-right: 2212px;
}

.ag-theme-modes .ag-ltr .ag-row-level-79 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-79 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-80 {
  padding-left: 2258px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-80 {
  padding-right: 2258px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-80 {
  padding-left: 2240px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-80 {
  padding-right: 2240px;
}

.ag-theme-modes .ag-ltr .ag-row-level-80 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-80 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-81 {
  padding-left: 2286px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-81 {
  padding-right: 2286px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-81 {
  padding-left: 2268px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-81 {
  padding-right: 2268px;
}

.ag-theme-modes .ag-ltr .ag-row-level-81 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-81 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-82 {
  padding-left: 2314px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-82 {
  padding-right: 2314px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-82 {
  padding-left: 2296px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-82 {
  padding-right: 2296px;
}

.ag-theme-modes .ag-ltr .ag-row-level-82 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-82 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-83 {
  padding-left: 2342px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-83 {
  padding-right: 2342px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-83 {
  padding-left: 2324px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-83 {
  padding-right: 2324px;
}

.ag-theme-modes .ag-ltr .ag-row-level-83 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-83 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-84 {
  padding-left: 2370px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-84 {
  padding-right: 2370px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-84 {
  padding-left: 2352px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-84 {
  padding-right: 2352px;
}

.ag-theme-modes .ag-ltr .ag-row-level-84 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-84 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-85 {
  padding-left: 2398px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-85 {
  padding-right: 2398px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-85 {
  padding-left: 2380px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-85 {
  padding-right: 2380px;
}

.ag-theme-modes .ag-ltr .ag-row-level-85 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-85 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-86 {
  padding-left: 2426px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-86 {
  padding-right: 2426px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-86 {
  padding-left: 2408px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-86 {
  padding-right: 2408px;
}

.ag-theme-modes .ag-ltr .ag-row-level-86 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-86 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-87 {
  padding-left: 2454px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-87 {
  padding-right: 2454px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-87 {
  padding-left: 2436px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-87 {
  padding-right: 2436px;
}

.ag-theme-modes .ag-ltr .ag-row-level-87 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-87 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-88 {
  padding-left: 2482px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-88 {
  padding-right: 2482px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-88 {
  padding-left: 2464px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-88 {
  padding-right: 2464px;
}

.ag-theme-modes .ag-ltr .ag-row-level-88 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-88 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-89 {
  padding-left: 2510px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-89 {
  padding-right: 2510px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-89 {
  padding-left: 2492px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-89 {
  padding-right: 2492px;
}

.ag-theme-modes .ag-ltr .ag-row-level-89 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-89 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-90 {
  padding-left: 2538px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-90 {
  padding-right: 2538px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-90 {
  padding-left: 2520px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-90 {
  padding-right: 2520px;
}

.ag-theme-modes .ag-ltr .ag-row-level-90 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-90 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-91 {
  padding-left: 2566px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-91 {
  padding-right: 2566px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-91 {
  padding-left: 2548px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-91 {
  padding-right: 2548px;
}

.ag-theme-modes .ag-ltr .ag-row-level-91 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-91 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-92 {
  padding-left: 2594px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-92 {
  padding-right: 2594px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-92 {
  padding-left: 2576px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-92 {
  padding-right: 2576px;
}

.ag-theme-modes .ag-ltr .ag-row-level-92 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-92 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-93 {
  padding-left: 2622px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-93 {
  padding-right: 2622px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-93 {
  padding-left: 2604px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-93 {
  padding-right: 2604px;
}

.ag-theme-modes .ag-ltr .ag-row-level-93 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-93 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-94 {
  padding-left: 2650px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-94 {
  padding-right: 2650px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-94 {
  padding-left: 2632px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-94 {
  padding-right: 2632px;
}

.ag-theme-modes .ag-ltr .ag-row-level-94 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-94 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-95 {
  padding-left: 2678px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-95 {
  padding-right: 2678px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-95 {
  padding-left: 2660px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-95 {
  padding-right: 2660px;
}

.ag-theme-modes .ag-ltr .ag-row-level-95 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-95 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-96 {
  padding-left: 2706px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-96 {
  padding-right: 2706px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-96 {
  padding-left: 2688px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-96 {
  padding-right: 2688px;
}

.ag-theme-modes .ag-ltr .ag-row-level-96 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-96 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-97 {
  padding-left: 2734px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-97 {
  padding-right: 2734px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-97 {
  padding-left: 2716px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-97 {
  padding-right: 2716px;
}

.ag-theme-modes .ag-ltr .ag-row-level-97 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-97 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-98 {
  padding-left: 2762px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-98 {
  padding-right: 2762px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-98 {
  padding-left: 2744px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-98 {
  padding-right: 2744px;
}

.ag-theme-modes .ag-ltr .ag-row-level-98 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-98 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-99 {
  padding-left: 2790px;
}

.ag-theme-modes .ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-99 {
  padding-right: 2790px;
}

.ag-theme-modes .ag-ltr .ag-row-group-indent-99 {
  padding-left: 2772px;
}

.ag-theme-modes .ag-rtl .ag-row-group-indent-99 {
  padding-right: 2772px;
}

.ag-theme-modes .ag-ltr .ag-row-level-99 .ag-pivot-leaf-group {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-level-99 .ag-pivot-leaf-group {
  margin-right: 28px;
}

.ag-theme-modes .ag-ltr .ag-row-group-leaf-indent {
  margin-left: 28px;
}

.ag-theme-modes .ag-rtl .ag-row-group-leaf-indent {
  margin-right: 28px;
}

.ag-theme-modes .ag-value-change-delta {
  padding-right: 2px;
}
.ag-theme-modes .ag-value-change-delta-up {
  color: #43a047;
  color: var(--ag-value-change-delta-up-color, #43a047);
}
.ag-theme-modes .ag-value-change-delta-down {
  color: #e53935;
  color: var(--ag-value-change-delta-down-color, #e53935);
}
.ag-theme-modes .ag-value-change-value {
  background-color: transparent;
  border-radius: 1px;
  padding-left: 1px;
  padding-right: 1px;
  -webkit-transition: background-color 1s;
  transition: background-color 1s;
}
.ag-theme-modes .ag-value-change-value-highlight {
  background-color: rgba(22, 160, 133, 0.5);
  background-color: var(
    --ag-value-change-value-highlight-background-color,
    rgba(22, 160, 133, 0.5)
  );
  -webkit-transition: background-color 0.1s;
  transition: background-color 0.1s;
}
.ag-theme-modes .ag-cell-data-changed {
  background-color: rgba(22, 160, 133, 0.5) !important;
  background-color: var(
    --ag-value-change-value-highlight-background-color,
    rgba(22, 160, 133, 0.5)
  ) !important;
}
.ag-theme-modes .ag-cell-data-changed-animation {
  background-color: transparent;
}
.ag-theme-modes .ag-cell-highlight {
  background-color: #2196f3 !important;
  background-color: var(
    --ag-range-selection-highlight-color,
    var(--ag-range-selection-border-color, #2196f3)
  ) !important;
}
.ag-theme-modes .ag-row {
  height: 32px;
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
  color: #181d1f;
  color: var(--ag-data-color, var(--ag-foreground-color, #181d1f));
}

.ag-row:not(:last-of-type) {
  border-width: 1px;
  border-color: #dde2eb;
  border-color: var(
    --ag-row-border-color,
    var(--ag-secondary-border-color, #dde2eb)
  );
  border-bottom-style: solid;
}
.ag-floating-filter {
  padding-left: 0px !important;
}
.ag-input-field-input {
  height: 32px !important;
}
.ag-theme-modes .ag-row-highlight-above::after,
.ag-theme-modes .ag-row-highlight-below::after {
  content: "";
  position: absolute;
  width: calc(100% - 1px);
  height: 1px;
  background-color: #2196f3;
  background-color: var(--ag-range-selection-border-color, #2196f3);
  left: 1px;
}
.ag-theme-modes .ag-row-highlight-above::after {
  top: -1px;
}
.ag-theme-modes .ag-row-highlight-above.ag-row-first::after {
  top: 0;
}
.ag-theme-modes .ag-row-highlight-below::after {
  bottom: 0px;
}
.ag-theme-modes .ag-row-odd {
  background-color: #fcfcfc;
  background-color: var(--ag-odd-row-background-color, #fcfcfc);
}
.ag-theme-modes
  .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible)
  .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
  border-right: solid 1px;
  border-right-color: #babfc7;
  border-right-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes
  .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible)
  .ag-horizontal-right-spacer:not(.ag-scroller-corner) {
  border-left: solid 1px;
  border-left-color: #babfc7;
  border-left-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-row-selected::before {
  content: "";
  background-color: rgba(33, 150, 243, 0.3);
  background-color: var(
    --ag-selected-row-background-color,
    rgba(33, 150, 243, 0.3)
  );
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ag-theme-modes .ag-row-hover:not(.ag-full-width-row)::before,
.ag-theme-modes .ag-row-hover.ag-full-width-row.ag-row-group::before {
  content: "";
  background-color: rgba(33, 150, 243, 0.1);
  background-color: var(--ag-row-hover-color, rgba(33, 150, 243, 0.1));
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.ag-theme-modes .ag-row-hover.ag-row-selected::before {
  background-color: rgba(33, 150, 243, 0.1);
  background-color: var(--ag-selected-row-background-color);

  background-image: var(--ag-selected-row-background-color);
}
.ag-theme-modes .ag-row-hover.ag-full-width-row.ag-row-group > * {
  position: relative;
}
.ag-theme-modes .ag-column-hover {
  background-color: rgba(33, 150, 243, 0.1);
  background-color: var(--ag-column-hover-color, rgba(33, 150, 243, 0.1));
}
.ag-theme-modes .ag-ltr .ag-right-aligned-cell {
  text-align: right;
}

.ag-theme-modes .ag-rtl .ag-right-aligned-cell {
  text-align: left;
}

.ag-theme-modes .ag-ltr .ag-right-aligned-cell .ag-cell-value,
.ag-theme-modes .ag-ltr .ag-right-aligned-cell .ag-group-value {
  margin-left: auto;
}

.ag-theme-modes .ag-rtl .ag-right-aligned-cell .ag-cell-value,
.ag-theme-modes .ag-rtl .ag-right-aligned-cell .ag-group-value {
  margin-right: auto;
}

.ag-theme-modes .ag-cell,
.ag-theme-modes .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  border: 1px solid transparent;
  line-height: min(var(--ag-line-height, 40px), 40px);
  padding-left: 12px;
  padding-right: 12px;
  -webkit-font-smoothing: subpixel-antialiased;
}
.ag-theme-modes .ag-row > .ag-cell-wrapper {
  padding-left: 12px;
  padding-right: 12px;
}
.ag-theme-modes .ag-row-dragging {
  cursor: move;
  opacity: 0.5;
}
.ag-theme-modes .ag-cell-inline-editing {
  border: solid 1px;
  border-color: #babfc7;
  border-color: var(--ag-border-color, #babfc7);
  background: #fff;
  background: var(--ag-background-color, #fff);
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
  box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
  padding: 6px;
  padding: 0;
  height: 100%;
  background-color: #f8f8f8;
  background-color: var(--ag-control-panel-background-color, #f8f8f8);
}
.ag-theme-modes .ag-popup-editor {
  border: solid 1px;
  border-color: #babfc7;
  border-color: var(--ag-border-color, #babfc7);
  background: #fff;
  background: var(--ag-background-color, #fff);
  border-radius: 3px;
  -webkit-box-shadow: var(--mo-elevation-4);
  box-shadow: var(--mo-elevation-4);
  padding: 6px;
  background-color: #fff;
  background-color: var(--ag-control-panel-background-color, #f8f8f8);
  padding: 0;
}
.ag-theme-modes .ag-large-text-input {
  height: auto;
  padding: 18px;
}
.ag-theme-modes .ag-details-row {
  padding: 30px;
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
}
.ag-theme-modes .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-modes .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-modes .ag-layout-print .ag-center-cols-clipper,
.ag-theme-modes .ag-layout-print .ag-center-cols-container {
  min-height: 50px;
}
.ag-theme-modes .ag-overlay-loading-wrapper {
  background-color: rgba(255, 255, 255, 0.66);
  background-color: var(
    --ag-modal-overlay-background-color,
    rgba(255, 255, 255, 0.66)
  );
  backdrop-filter: blur(1.5px);
}

.ag-overlay-wrapper {
  background-color: var(
    --ag-modal-overlay-background-color,
    rgba(255, 255, 255, 0.66)
  );
  backdrop-filter: blur(1.5px);
}
.ag-theme-modes .ag-overlay-loading-center {
  border: solid 1px;
  border-color: #babfc7;
  border-color: var(--ag-border-color, #babfc7);
  background: #fff;
  background: var(--ag-background-color, #fff);
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
  box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
  padding: 6px;
}
.ag-theme-modes .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
  padding-top: 30px;
}
.ag-theme-modes .ag-loading {
  padding-left: 12px;
  display: -webkit-box;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
}
.ag-theme-modes .ag-loading-icon {
  padding-right: 12px;
}
.ag-theme-modes .ag-icon-loading {
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.ag-theme-modes .ag-floating-top {
  border-bottom: solid 1px;
  border-bottom-color: #babfc7;
  border-bottom-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-floating-bottom {
  border-top: solid 1px;
  border-top-color: #babfc7;
  border-top-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-ltr .ag-cell {
  border-right: solid transparent;
}

.ag-theme-modes .ag-rtl .ag-cell {
  border-left: solid transparent;
}

.ag-theme-modes .ag-ltr .ag-cell {
  border-right-width: 1px;
}

.ag-theme-modes .ag-rtl .ag-cell {
  border-left-width: 1px;
}

.ag-theme-modes
  .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(
    .ag-cell-range-single-cell
  ) {
  border-left: solid 1px;
  border-left-color: #babfc7;
  border-left-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes
  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
    .ag-cell-range-single-cell
  ) {
  border-right: solid 1px var(--mo-color-primary-7) !important;
}
.ag-theme-modes .ag-cell-range-selected:not(.ag-cell-focus),
.ag-theme-modes
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
  background-color: rgba(33, 150, 243, 0.2);
  background-color: var(
    --ag-range-selection-background-color,
    rgba(33, 150, 243, 0.2)
  );
}
.ag-theme-modes .ag-cell-range-selected:not(.ag-cell-focus).ag-cell-range-chart,
.ag-theme-modes
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-single-cell:not(.ag-cell-inline-editing).ag-cell-range-chart {
  background-color: rgba(0, 88, 255, 0.1) !important;
  background-color: var(
    --ag-range-selection-chart-background-color,
    rgba(0, 88, 255, 0.1)
  ) !important;
}
.ag-theme-modes
  .ag-cell-range-selected:not(
    .ag-cell-focus
  ).ag-cell-range-chart.ag-cell-range-chart-category,
.ag-theme-modes
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-single-cell:not(
    .ag-cell-inline-editing
  ).ag-cell-range-chart.ag-cell-range-chart-category {
  background-color: rgba(0, 255, 132, 0.1) !important;
  background-color: var(
    --ag-range-selection-chart-category-background-color,
    rgba(0, 255, 132, 0.1)
  ) !important;
}
.ag-theme-modes .ag-cell-range-selected-1:not(.ag-cell-focus),
.ag-theme-modes
  .ag-root:not(.ag-context-menu-open)
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
  background-color: rgba(33, 150, 243, 0.2);
  background-color: var(
    --ag-range-selection-background-color-1,
    var(--ag-range-selection-background-color, rgba(33, 150, 243, 0.2))
  );
}
.ag-theme-modes .ag-cell-range-selected-2:not(.ag-cell-focus),
.ag-theme-modes .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-2 {
  background-color: rgba(33, 150, 243, 0.36);
  background-color: var(
    --ag-range-selection-background-color-2,
    rgba(33, 150, 243, 0.36)
  );
}
.ag-theme-modes .ag-cell-range-selected-3:not(.ag-cell-focus),
.ag-theme-modes .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-3 {
  background-color: rgba(33, 150, 243, 0.488);
  background-color: var(
    --ag-range-selection-background-color-3,
    rgba(33, 150, 243, 0.488)
  );
}
.ag-theme-modes .ag-cell-range-selected-4:not(.ag-cell-focus),
.ag-theme-modes .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-4 {
  background-color: rgba(33, 150, 243, 0.5904);
  background-color: var(
    --ag-range-selection-background-color-4,
    rgba(33, 150, 243, 0.5904)
  );
}
.ag-theme-modes
  .ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-top {
  border-top-color: #2196f3;
  border-top-color: var(--ag-range-selection-border-color, #2196f3);
}
.ag-theme-modes
  .ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-right {
  border-right-color: #2196f3;
  border-right-color: var(--ag-range-selection-border-color, #2196f3);
}
.ag-theme-modes
  .ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-bottom {
  border-bottom-color: #2196f3;
  border-bottom-color: var(--ag-range-selection-border-color, #2196f3);
}
.ag-theme-modes
  .ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-left {
  border-left-color: #2196f3;
  border-left-color: var(--ag-range-selection-border-color, #2196f3);
}
.ag-theme-modes
  .ag-ltr
  .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-theme-modes
  .ag-ltr
  .ag-context-menu-open
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-modes
  .ag-ltr
  .ag-full-width-row.ag-row-focus:focus
  .ag-cell-wrapper.ag-row-group,
.ag-theme-modes .ag-ltr .ag-cell-range-single-cell,
.ag-theme-modes .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-theme-modes
  .ag-rtl
  .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-theme-modes
  .ag-rtl
  .ag-context-menu-open
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-modes
  .ag-rtl
  .ag-full-width-row.ag-row-focus:focus
  .ag-cell-wrapper.ag-row-group,
.ag-theme-modes .ag-rtl .ag-cell-range-single-cell,
.ag-theme-modes .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border-color: transparent;
  border-bottom: 1px solid var(--mo-color-primary-30, var(--mo-color-primary-7)) !important;
  box-shadow: inset 0 -1px 0 0px var(--mo-color-primary-7);
  background-color: var(--mo-color-primary-70, var(--mo-color-secondary-70));
  outline: initial;
}
.ag-theme-modes .ag-cell.ag-selection-fill-top,
.ag-theme-modes .ag-cell.ag-selection-fill-top.ag-cell-range-selected {
  border-top: 1px dashed;
  border-top-color: #2196f3;
  border-top-color: var(--ag-range-selection-border-color, #2196f3);
}
.ag-theme-modes .ag-ltr .ag-cell.ag-selection-fill-right,
.ag-theme-modes
  .ag-ltr
  .ag-cell.ag-selection-fill-right.ag-cell-range-selected {
  border-right: 1px dashed !important;
  border-right-color: #2196f3 !important;
  border-right-color: var(
    --ag-range-selection-border-color,
    #2196f3
  ) !important;
}

.ag-theme-modes .ag-rtl .ag-cell.ag-selection-fill-right,
.ag-theme-modes
  .ag-rtl
  .ag-cell.ag-selection-fill-right.ag-cell-range-selected {
  border-left: 1px dashed !important;
  border-left-color: #2196f3 !important;
  border-left-color: var(--ag-range-selection-border-color, #2196f3) !important;
}

.ag-theme-modes .ag-cell.ag-selection-fill-bottom,
.ag-theme-modes .ag-cell.ag-selection-fill-bottom.ag-cell-range-selected {
  border-bottom: 1px dashed;
  border-bottom-color: #2196f3;
  border-bottom-color: var(--ag-range-selection-border-color, #2196f3);
}
.ag-theme-modes .ag-ltr .ag-cell.ag-selection-fill-left,
.ag-theme-modes .ag-ltr .ag-cell.ag-selection-fill-left.ag-cell-range-selected {
  border-left: 1px dashed !important;
  border-left-color: #2196f3 !important;
  border-left-color: var(--ag-range-selection-border-color, #2196f3) !important;
}

.ag-theme-modes .ag-rtl .ag-cell.ag-selection-fill-left,
.ag-theme-modes .ag-rtl .ag-cell.ag-selection-fill-left.ag-cell-range-selected {
  border-right: 1px dashed !important;
  border-right-color: #2196f3 !important;
  border-right-color: var(
    --ag-range-selection-border-color,
    #2196f3
  ) !important;
}

.ag-theme-modes .ag-range-handle,
.ag-theme-modes .ag-fill-handle {
  position: absolute;
  width: 6px;
  height: 6px;
  bottom: -1px;
  background-color: #2196f3;
  background-color: var(--ag-range-selection-border-color, #2196f3);
}
.ag-theme-modes .ag-ltr .ag-range-handle,
.ag-theme-modes .ag-ltr .ag-fill-handle {
  right: -1px;
}

.ag-theme-modes .ag-rtl .ag-range-handle,
.ag-theme-modes .ag-rtl .ag-fill-handle {
  left: -1px;
}

.ag-theme-modes .ag-fill-handle {
  cursor: cell;
}
.ag-theme-modes .ag-range-handle {
  cursor: nwse-resize;
}
.ag-theme-modes .ag-cell-inline-editing {
  border-color: rgba(33, 150, 243, 0.4) !important;
  border-color: var(
    --ag-input-focus-border-color,
    rgba(33, 150, 243, 0.4)
  ) !important;
}
.ag-theme-modes .ag-menu {
  border: solid 1px;
  border-color: #babfc7;
  border-color: var(--ag-border-color, #babfc7);
  background: #fff;
  background: var(--ag-background-color, #fff);
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
  box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
  padding: 6px;
  padding: 0;
}
.ag-theme-modes .ag-menu-list {
  cursor: default;
  padding: 6px 0;
}
.ag-theme-modes .ag-menu-separator {
  height: 13px;
}
.ag-theme-modes .ag-menu-separator-part::after {
  content: "";
  display: block;
  border-top: solid 1px;
  border-top-color: #babfc7;
  border-top-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-menu-option-active,
.ag-theme-modes .ag-compact-menu-option-active {
  background-color: rgba(33, 150, 243, 0.1);
  background-color: var(--ag-row-hover-color, rgba(33, 150, 243, 0.1));
}
.ag-theme-modes .ag-menu-option-part,
.ag-theme-modes .ag-compact-menu-option-part {
  line-height: 16px;
  padding: 8px 0;
}
.ag-theme-modes .ag-menu-option-disabled,
.ag-theme-modes .ag-compact-menu-option-disabled {
  opacity: 0.5;
}
.ag-theme-modes .ag-menu-option-icon,
.ag-theme-modes .ag-compact-menu-option-icon {
  width: 16px;
}
.ag-theme-modes .ag-ltr .ag-menu-option-icon,
.ag-theme-modes .ag-ltr .ag-compact-menu-option-icon {
  padding-left: 12px;
}

.ag-theme-modes .ag-rtl .ag-menu-option-icon,
.ag-theme-modes .ag-rtl .ag-compact-menu-option-icon {
  padding-right: 12px;
}

.ag-theme-modes .ag-menu-option-text,
.ag-theme-modes .ag-compact-menu-option-text {
  padding-left: 12px;
  padding-right: 12px;
}
.ag-theme-modes .ag-ltr .ag-menu-option-shortcut,
.ag-theme-modes .ag-ltr .ag-compact-menu-option-shortcut {
  padding-right: 6px;
}

.ag-theme-modes .ag-rtl .ag-menu-option-shortcut,
.ag-theme-modes .ag-rtl .ag-compact-menu-option-shortcut {
  padding-left: 6px;
}

.ag-theme-modes .ag-menu-option-popup-pointer,
.ag-theme-modes .ag-compact-menu-option-popup-pointer {
  padding-right: 6px;
}
.ag-theme-modes .ag-tabs {
  min-width: 240px;
}
.ag-theme-modes .ag-tabs-header {
  width: 100%;
  display: -webkit-box;
  display: flex;
}
.ag-theme-modes .ag-tab {
  border-bottom: 2px solid transparent;
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 0;
  flex: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  -webkit-transition: border-bottom 0.3s;
  transition: border-bottom 0.3s;
}
.ag-theme-modes .ag-keyboard-focus .ag-tab:focus {
  outline: none;
}
.ag-theme-modes .ag-keyboard-focus .ag-tab:focus::after {
  content: "";
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 4px;
  left: 4px;
  display: block;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid;
  border-color: rgba(33, 150, 243, 0.4);
  border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes .ag-tab-selected {
  border-bottom-color: #2196f3;
  border-bottom-color: var(
    --ag-selected-tab-underline-color,
    var(--ag-alpine-active-color, #2196f3)
  );
}
.ag-theme-modes .ag-menu-header {
  color: #181d1f;
  color: var(
    --ag-secondary-foreground-color,
    var(--ag-foreground-color, #181d1f)
  );
}
.ag-theme-modes .ag-filter-separator {
  border-top: solid 1px;
  border-top-color: #babfc7;
  border-top-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-menu:not(.ag-tabs) .ag-filter-select {
  min-width: 155px;
}
.ag-theme-modes .ag-tabs .ag-filter-select {
  min-width: 214px;
}
.ag-theme-modes .ag-filter-select .ag-picker-field-wrapper {
  width: 0;
}
.ag-theme-modes .ag-filter-condition-operator {
  height: 17px;
}
.ag-theme-modes .ag-ltr .ag-filter-condition-operator-or {
  margin-left: 12px;
}

.ag-theme-modes .ag-rtl .ag-filter-condition-operator-or {
  margin-right: 12px;
}

.ag-theme-modes .ag-set-filter-select-all {
  padding-top: 12px;
}
.ag-theme-modes .ag-set-filter-list,
.ag-theme-modes .ag-filter-no-matches {
  height: 144px;
}
.ag-theme-modes .ag-set-filter-filter {
  margin-top: 12px;
  margin-left: 12px;
  margin-right: 12px;
}
.ag-theme-modes .ag-filter-to {
  margin-top: 9px;
}
.ag-theme-modes .ag-mini-filter {
  margin: 12px 12px;
}
.ag-theme-modes .ag-set-filter-item {
  margin: 0px 12px;
}
.ag-theme-modes .ag-ltr .ag-set-filter-item-value {
  margin-left: 12px;
}

.ag-theme-modes .ag-rtl .ag-set-filter-item-value {
  margin-right: 12px;
}

.ag-theme-modes .ag-filter-apply-panel {
  padding: 12px 12px;
  border-top: solid 1px;
  border-top-color: #dde2eb;
  border-top-color: var(--ag-secondary-border-color, #dde2eb);
}
.ag-theme-modes .ag-filter-apply-panel-button {
  line-height: 1.5;
}
.ag-theme-modes .ag-ltr .ag-filter-apply-panel-button {
  margin-left: 12px;
}

.ag-theme-modes .ag-rtl .ag-filter-apply-panel-button {
  margin-right: 12px;
}

.ag-theme-modes .ag-simple-filter-body-wrapper {
  padding: 16px;
  padding-bottom: 7px;
}
.ag-theme-modes .ag-simple-filter-body-wrapper > * {
  margin-bottom: 9px;
}
.ag-theme-modes .ag-filter-no-matches {
  padding: 12px 12px;
}
.ag-theme-modes .ag-multi-filter-menu-item {
  margin: 6px 0;
}
.ag-theme-modes .ag-multi-filter-group-title-bar {
  padding: 12px 6px;
  background-color: transparent;
}
.ag-theme-modes .ag-keyboard-focus .ag-multi-filter-group-title-bar:focus {
  outline: none;
}
.ag-theme-modes
  .ag-keyboard-focus
  .ag-multi-filter-group-title-bar:focus::after {
  content: "";
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 4px;
  left: 4px;
  display: block;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid;
  border-color: rgba(33, 150, 243, 0.4);
  border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes .ag-side-bar {
  position: relative;
}
.ag-theme-modes .ag-tool-panel-wrapper {
  width: 250px;
  background-color: #f8f8f8;
  background-color: var(--ag-control-panel-background-color, #f8f8f8);
}
.ag-theme-modes .ag-side-buttons {
  padding-top: 24px;
  width: 20px;
  position: relative;
  color: #181d1f;
  color: var(--ag-foreground-color, #181d1f);
  overflow: hidden;
}
.ag-theme-modes button.ag-side-button-button {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  background: transparent;
  padding: 12px 0 12px 0;
  width: 100%;
  margin: 0;
  min-height: 108px;
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
  border: none;
}
.ag-theme-modes button.ag-side-button-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ag-theme-modes .ag-keyboard-focus .ag-side-button-button:focus {
  outline: none;
}
.ag-theme-modes .ag-keyboard-focus .ag-side-button-button:focus::after {
  content: "";
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 4px;
  left: 4px;
  display: block;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid;
  border-color: rgba(33, 150, 243, 0.4);
  border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes .ag-side-button-icon-wrapper {
  margin-bottom: 3px;
}
.ag-theme-modes .ag-ltr .ag-side-bar-left,
.ag-theme-modes .ag-rtl .ag-side-bar-right {
  border-right: solid 1px;
  border-right-color: #babfc7;
  border-right-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-ltr .ag-side-bar-left .ag-tool-panel-wrapper,
.ag-theme-modes .ag-rtl .ag-side-bar-right .ag-tool-panel-wrapper {
  border-left: solid 1px;
  border-left-color: #babfc7;
  border-left-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-ltr .ag-side-bar-left .ag-side-button-button,
.ag-theme-modes .ag-rtl .ag-side-bar-right .ag-side-button-button {
  border-right: 2px solid transparent;
  -webkit-transition: border-right 0.3s;
  transition: border-right 0.3s;
}
.ag-theme-modes .ag-ltr .ag-side-bar-left .ag-selected .ag-side-button-button,
.ag-theme-modes .ag-rtl .ag-side-bar-right .ag-selected .ag-side-button-button {
  border-right-color: #2196f3;
  border-right-color: var(
    --ag-selected-tab-underline-color,
    var(--ag-alpine-active-color, #2196f3)
  );
}
.ag-theme-modes .ag-rtl .ag-side-bar-left,
.ag-theme-modes .ag-ltr .ag-side-bar-right {
  border-left: solid 1px;
  border-left-color: #babfc7;
  border-left-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-rtl .ag-side-bar-left .ag-tool-panel-wrapper,
.ag-theme-modes .ag-ltr .ag-side-bar-right .ag-tool-panel-wrapper {
  border-right: solid 1px;
  border-right-color: #babfc7;
  border-right-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-rtl .ag-side-bar-left .ag-side-button-button,
.ag-theme-modes .ag-ltr .ag-side-bar-right .ag-side-button-button {
  border-left: 2px solid transparent;
  -webkit-transition: border-left 0.3s;
  transition: border-left 0.3s;
}
.ag-theme-modes .ag-rtl .ag-side-bar-left .ag-selected .ag-side-button-button,
.ag-theme-modes .ag-ltr .ag-side-bar-right .ag-selected .ag-side-button-button {
  border-left-color: #2196f3;
  border-left-color: var(
    --ag-selected-tab-underline-color,
    var(--ag-alpine-active-color, #2196f3)
  );
}
.ag-theme-modes .ag-filter-toolpanel-header {
  height: 36px;
}
.ag-theme-modes .ag-ltr .ag-filter-toolpanel-header,
.ag-theme-modes .ag-ltr .ag-filter-toolpanel-search {
  padding-left: 6px;
}

.ag-theme-modes .ag-rtl .ag-filter-toolpanel-header,
.ag-theme-modes .ag-rtl .ag-filter-toolpanel-search {
  padding-right: 6px;
}

.ag-theme-modes .ag-keyboard-focus .ag-filter-toolpanel-header:focus {
  outline: none;
}
.ag-theme-modes .ag-keyboard-focus .ag-filter-toolpanel-header:focus::after {
  content: "";
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 4px;
  left: 4px;
  display: block;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid;
  border-color: rgba(33, 150, 243, 0.4);
  border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes
  .ag-filter-toolpanel-group.ag-has-filter
  > .ag-group-title-bar
  .ag-group-title::after {
  font-family: "agGridAlpine";
  font-size: 16px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\\f114";
  position: absolute;
}
.ag-theme-modes
  .ag-ltr
  .ag-filter-toolpanel-group.ag-has-filter
  > .ag-group-title-bar
  .ag-group-title::after {
  padding-left: 6px;
}

.ag-theme-modes
  .ag-rtl
  .ag-filter-toolpanel-group.ag-has-filter
  > .ag-group-title-bar
  .ag-group-title::after {
  padding-right: 6px;
}

.ag-theme-modes .ag-filter-toolpanel-group-level-0-header {
  height: 48px;
}
.ag-theme-modes .ag-filter-toolpanel-group-item {
  margin-top: 3px;
  margin-bottom: 3px;
}
.ag-theme-modes .ag-filter-toolpanel-search {
  height: 48px;
}
.ag-theme-modes .ag-filter-toolpanel-search-input {
  -webkit-box-flex: 1;
  flex-grow: 1;
  height: 24px;
}
.ag-theme-modes .ag-ltr .ag-filter-toolpanel-search-input {
  margin-right: 6px;
}

.ag-theme-modes .ag-rtl .ag-filter-toolpanel-search-input {
  margin-left: 6px;
}

.ag-theme-modes .ag-filter-toolpanel-group-level-0 {
  border-top: solid 1px;
  border-top-color: #dde2eb;
  border-top-color: var(--ag-secondary-border-color, #dde2eb);
}
.ag-theme-modes .ag-ltr .ag-filter-toolpanel-expand,
.ag-theme-modes .ag-ltr .ag-filter-toolpanel-group-title-bar-icon {
  margin-right: 6px;
}

.ag-theme-modes .ag-rtl .ag-filter-toolpanel-expand,
.ag-theme-modes .ag-rtl .ag-filter-toolpanel-group-title-bar-icon {
  margin-left: 6px;
}

.ag-theme-modes
  .ag-filter-toolpanel-group-level-1
  .ag-filter-toolpanel-group-level-1-header.ag-filter-toolpanel-group-title-bar {
  background-color: transparent;
}
.ag-theme-modes
  .ag-ltr
  .ag-filter-toolpanel-group-level-1
  .ag-filter-toolpanel-group-level-2-header {
  padding-left: 22px;
}

.ag-theme-modes
  .ag-rtl
  .ag-filter-toolpanel-group-level-1
  .ag-filter-toolpanel-group-level-2-header {
  padding-right: 22px;
}

.ag-theme-modes
  .ag-filter-toolpanel-group-level-2
  .ag-filter-toolpanel-group-level-2-header.ag-filter-toolpanel-group-title-bar {
  background-color: transparent;
}
.ag-theme-modes
  .ag-ltr
  .ag-filter-toolpanel-group-level-2
  .ag-filter-toolpanel-group-level-3-header {
  padding-left: 38px;
}

.ag-theme-modes
  .ag-rtl
  .ag-filter-toolpanel-group-level-2
  .ag-filter-toolpanel-group-level-3-header {
  padding-right: 38px;
}

.ag-theme-modes
  .ag-filter-toolpanel-group-level-3
  .ag-filter-toolpanel-group-level-3-header.ag-filter-toolpanel-group-title-bar {
  background-color: transparent;
}
.ag-theme-modes
  .ag-ltr
  .ag-filter-toolpanel-group-level-3
  .ag-filter-toolpanel-group-level-4-header {
  padding-left: 54px;
}

.ag-theme-modes
  .ag-rtl
  .ag-filter-toolpanel-group-level-3
  .ag-filter-toolpanel-group-level-4-header {
  padding-right: 54px;
}

.ag-theme-modes
  .ag-filter-toolpanel-group-level-4
  .ag-filter-toolpanel-group-level-4-header.ag-filter-toolpanel-group-title-bar {
  background-color: transparent;
}
.ag-theme-modes
  .ag-ltr
  .ag-filter-toolpanel-group-level-4
  .ag-filter-toolpanel-group-level-5-header {
  padding-left: 70px;
}

.ag-theme-modes
  .ag-rtl
  .ag-filter-toolpanel-group-level-4
  .ag-filter-toolpanel-group-level-5-header {
  padding-right: 70px;
}

.ag-theme-modes
  .ag-filter-toolpanel-group-level-5
  .ag-filter-toolpanel-group-level-5-header.ag-filter-toolpanel-group-title-bar {
  background-color: transparent;
}
.ag-theme-modes
  .ag-ltr
  .ag-filter-toolpanel-group-level-5
  .ag-filter-toolpanel-group-level-6-header {
  padding-left: 86px;
}

.ag-theme-modes
  .ag-rtl
  .ag-filter-toolpanel-group-level-5
  .ag-filter-toolpanel-group-level-6-header {
  padding-right: 86px;
}

.ag-theme-modes
  .ag-filter-toolpanel-group-level-6
  .ag-filter-toolpanel-group-level-6-header.ag-filter-toolpanel-group-title-bar {
  background-color: transparent;
}
.ag-theme-modes
  .ag-ltr
  .ag-filter-toolpanel-group-level-6
  .ag-filter-toolpanel-group-level-7-header {
  padding-left: 102px;
}

.ag-theme-modes
  .ag-rtl
  .ag-filter-toolpanel-group-level-6
  .ag-filter-toolpanel-group-level-7-header {
  padding-right: 102px;
}

.ag-theme-modes
  .ag-filter-toolpanel-group-level-7
  .ag-filter-toolpanel-group-level-7-header.ag-filter-toolpanel-group-title-bar {
  background-color: transparent;
}
.ag-theme-modes
  .ag-ltr
  .ag-filter-toolpanel-group-level-7
  .ag-filter-toolpanel-group-level-8-header {
  padding-left: 118px;
}

.ag-theme-modes
  .ag-rtl
  .ag-filter-toolpanel-group-level-7
  .ag-filter-toolpanel-group-level-8-header {
  padding-right: 118px;
}

.ag-theme-modes
  .ag-filter-toolpanel-group-level-8
  .ag-filter-toolpanel-group-level-8-header.ag-filter-toolpanel-group-title-bar {
  background-color: transparent;
}
.ag-theme-modes
  .ag-ltr
  .ag-filter-toolpanel-group-level-8
  .ag-filter-toolpanel-group-level-9-header {
  padding-left: 134px;
}

.ag-theme-modes
  .ag-rtl
  .ag-filter-toolpanel-group-level-8
  .ag-filter-toolpanel-group-level-9-header {
  padding-right: 134px;
}

.ag-theme-modes
  .ag-filter-toolpanel-group-level-9
  .ag-filter-toolpanel-group-level-9-header.ag-filter-toolpanel-group-title-bar {
  background-color: transparent;
}
.ag-theme-modes
  .ag-ltr
  .ag-filter-toolpanel-group-level-9
  .ag-filter-toolpanel-group-level-10-header {
  padding-left: 150px;
}

.ag-theme-modes
  .ag-rtl
  .ag-filter-toolpanel-group-level-9
  .ag-filter-toolpanel-group-level-10-header {
  padding-right: 150px;
}

.ag-theme-modes
  .ag-filter-toolpanel-group-level-10
  .ag-filter-toolpanel-group-level-10-header.ag-filter-toolpanel-group-title-bar {
  background-color: transparent;
}
.ag-theme-modes
  .ag-ltr
  .ag-filter-toolpanel-group-level-10
  .ag-filter-toolpanel-group-level-11-header {
  padding-left: 166px;
}

.ag-theme-modes
  .ag-rtl
  .ag-filter-toolpanel-group-level-10
  .ag-filter-toolpanel-group-level-11-header {
  padding-right: 166px;
}

.ag-theme-modes
  .ag-filter-toolpanel-instance-header.ag-filter-toolpanel-group-level-1-header {
  padding-left: 6px;
}
.ag-theme-modes .ag-filter-toolpanel-instance-filter {
  border-top: solid 1px;
  border-top-color: #babfc7;
  border-top-color: var(--ag-border-color, #babfc7);
  border-bottom: solid 1px;
  border-bottom-color: #babfc7;
  border-bottom-color: var(--ag-border-color, #babfc7);
  margin-top: 6px;
}
.ag-theme-modes .ag-ltr .ag-filter-toolpanel-instance-header-icon {
  margin-left: 6px;
}

.ag-theme-modes .ag-rtl .ag-filter-toolpanel-instance-header-icon {
  margin-right: 6px;
}

.ag-theme-modes .ag-pivot-mode-panel {
  min-height: 48px;
  height: 48px;
  display: -webkit-box;
  display: flex;
}
.ag-theme-modes .ag-pivot-mode-select {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.ag-theme-modes .ag-ltr .ag-pivot-mode-select {
  margin-left: 12px;
}

.ag-theme-modes .ag-rtl .ag-pivot-mode-select {
  margin-right: 12px;
}

.ag-theme-modes .ag-keyboard-focus .ag-column-select-header:focus {
  outline: none;
}
.ag-theme-modes .ag-keyboard-focus .ag-column-select-header:focus::after {
  content: "";
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 4px;
  left: 4px;
  display: block;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid;
  border-color: rgba(33, 150, 243, 0.4);
  border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes .ag-column-select-header {
  height: 48px;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 12px;
  border-bottom: solid 1px;
  border-bottom-color: #dde2eb;
  border-bottom-color: var(--ag-secondary-border-color, #dde2eb);
}
.ag-theme-modes .ag-column-panel-column-select {
  border-bottom: solid 1px;
  border-bottom-color: #dde2eb;
  border-bottom-color: var(--ag-secondary-border-color, #dde2eb);
  border-top: solid 1px;
  border-top-color: #dde2eb;
  border-top-color: var(--ag-secondary-border-color, #dde2eb);
}
.ag-theme-modes .ag-column-group-icons,
.ag-theme-modes .ag-column-select-header-icon {
  color: #181d1f;
  color: var(
    --ag-secondary-foreground-color,
    var(--ag-foreground-color, #181d1f)
  );
}
.ag-theme-modes .ag-column-select-list .ag-list-item-hovered::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #2196f3;
  background-color: var(--ag-range-selection-border-color, #2196f3);
}
.ag-theme-modes .ag-column-select-list .ag-item-highlight-top::after {
  top: 0;
}
.ag-theme-modes .ag-column-select-list .ag-item-highlight-bottom::after {
  bottom: 0;
}
.ag-theme-modes .ag-header {
  background-color: #f8f8f8;
  background-color: var(--ag-header-background-color, #f8f8f8);
  border-bottom: solid 1px;
  border-bottom-color: var(--mo-color-primary-7);
}
.ag-theme-modes .ag-header-row {
  color: #181d1f;
  color: var(
    --ag-header-foreground-color,
    var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f))
  );
  height: 32px;
}
.ag-theme-modes .ag-pinned-right-header {
  border-left: solid 1px;
  border-left-color: #babfc7;
  border-left-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-pinned-left-header {
  border-right: solid 1px;
  border-right-color: #babfc7;
  border-right-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes
  .ag-ltr
  .ag-header-cell:not(.ag-right-aligned-header)
  .ag-header-label-icon {
  margin-left: 6px;
}

.ag-theme-modes
  .ag-rtl
  .ag-header-cell:not(.ag-right-aligned-header)
  .ag-header-label-icon {
  margin-right: 6px;
}

.ag-theme-modes
  .ag-ltr
  .ag-header-cell.ag-right-aligned-header
  .ag-header-label-icon {
  margin-right: 6px;
}

.ag-theme-modes
  .ag-rtl
  .ag-header-cell.ag-right-aligned-header
  .ag-header-label-icon {
  margin-left: 6px;
}

.ag-theme-modes .ag-header-cell,
.ag-theme-modes .ag-header-group-cell {
  padding-left: 12px;
  padding-right: 12px;
}
.ag-theme-modes .ag-header-cell.ag-header-cell-moving,
.ag-theme-modes .ag-header-group-cell.ag-header-cell-moving {
  background-color: var(
    --ag-header-cell-moving-background-color,
    var(--ag-background-color, #fff)
  );
}
.ag-theme-modes .ag-keyboard-focus .ag-header-cell:focus {
  outline: none;
}
.ag-theme-modes .ag-keyboard-focus .ag-header-cell:focus::after {
  content: "";
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 4px;
  left: 4px;
  display: block;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid;
  border-color: rgba(33, 150, 243, 0.4);
  border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes .ag-keyboard-focus .ag-header-group-cell:focus {
  outline: none;
}
.ag-theme-modes .ag-keyboard-focus .ag-header-group-cell:focus::after {
  content: "";
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 4px;
  left: 4px;
  display: block;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid;
  border-color: rgba(33, 150, 243, 0.4);
  border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes .ag-header-icon {
  color: #181d1f;
  color: var(
    --ag-secondary-foreground-color,
    var(--ag-foreground-color, #181d1f)
  );
}
.ag-theme-modes .ag-header-expand-icon {
  cursor: pointer;
}
.ag-theme-modes .ag-ltr .ag-header-expand-icon {
  padding-left: 4px;
}

.ag-theme-modes .ag-rtl .ag-header-expand-icon {
  padding-right: 4px;
}

.ag-theme-modes .ag-header-row:not(:first-child) .ag-header-cell,
.ag-theme-modes
  .ag-header-row:not(:first-child)
  .ag-header-group-cell.ag-header-group-cell-with-group {
  border-top: solid 1px;
  border-top-color: #babfc7;
  border-top-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-header-cell-resize {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.ag-theme-modes .ag-header-cell-resize::after {
  content: "";
  position: absolute;
  z-index: 1;
  display: block;
  left: calc(50% - 1px);
  width: 2px;
  height: 30%;
  top: calc(50% - 15%);
  background-color: rgba(186, 191, 199, 0.5);
  background-color: var(
    --ag-header-column-resize-handle-color,
    rgba(186, 191, 199, 0.5)
  );
}
.ag-theme-modes .ag-pinned-right-header .ag-header-cell-resize::after {
  left: calc(50% - 2px);
}
.ag-theme-modes .ag-ltr .ag-header-select-all {
  margin-right: 12px;
}

.ag-theme-modes .ag-rtl .ag-header-select-all {
  margin-left: 12px;
}

.ag-theme-modes .ag-ltr .ag-floating-filter-button {
  margin-left: 12px;
}

.ag-theme-modes .ag-rtl .ag-floating-filter-button {
  margin-right: 12px;
}

.ag-theme-modes .ag-floating-filter-button-button {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  height: 16px;
  padding: 0;
  width: 16px;
}
.ag-theme-modes .ag-filter-loading {
  background-color: #f8f8f8;
  background-color: var(--ag-control-panel-background-color, #f8f8f8);
  height: 100%;
  padding: 12px 12px;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.ag-theme-modes .ag-paging-panel {
  border-top: 1px solid;
  border-top-color: #babfc7;
  border-top-color: var(--ag-border-color, #babfc7);
  color: #181d1f;
  color: var(
    --ag-secondary-foreground-color,
    var(--ag-foreground-color, #181d1f)
  );
  height: 48px;
}
.ag-theme-modes .ag-paging-panel > * {
  margin: 0 18px;
}
.ag-theme-modes .ag-paging-button {
  cursor: pointer;
}
.ag-theme-modes .ag-paging-button.ag-disabled {
  cursor: default;
  color: rgba(24, 29, 31, 0.5);
  color: var(--ag-disabled-foreground-color, rgba(24, 29, 31, 0.5));
}
.ag-theme-modes .ag-keyboard-focus .ag-paging-button:focus {
  outline: none;
}
.ag-theme-modes .ag-keyboard-focus .ag-paging-button:focus::after {
  content: "";
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 0px;
  left: 0px;
  display: block;
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  border: 1px solid;
  border-color: rgba(33, 150, 243, 0.4);
  border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes .ag-paging-button,
.ag-theme-modes .ag-paging-description {
  margin: 0 6px;
}
.ag-theme-modes .ag-status-bar {
  border-top: solid 1px;
  border-top-color: #babfc7;
  border-top-color: var(--ag-border-color, #babfc7);
  color: rgba(24, 29, 31, 0.5);
  color: var(--ag-disabled-foreground-color, rgba(24, 29, 31, 0.5));
  padding-right: 24px;
  padding-left: 24px;
  line-height: 1.5;
}
.ag-theme-modes .ag-status-name-value-value {
  color: #181d1f;
  color: var(--ag-foreground-color, #181d1f);
}
.ag-theme-modes .ag-status-bar-center {
  text-align: center;
}
.ag-theme-modes .ag-status-name-value {
  margin-left: 6px;
  margin-right: 6px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.ag-theme-modes .ag-column-drop-cell {
  background: rgba(24, 29, 31, 0.07);
  background: var(--ag-chip-background-color, rgba(24, 29, 31, 0.07));
  border-radius: 24px;
  height: 24px;
  padding: 0 3px;
  border: 1px solid transparent;
  cursor: pointer;
}
.ag-theme-modes .ag-keyboard-focus .ag-column-drop-cell:focus {
  outline: none;
}
.ag-theme-modes .ag-keyboard-focus .ag-column-drop-cell:focus::after {
  content: "";
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 2px;
  left: 2px;
  display: block;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border: 1px solid;
  border-color: rgba(33, 150, 243, 0.4);
  border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes .ag-column-drop-cell-text {
  margin: 0 6px;
}
.ag-theme-modes .ag-column-drop-cell-button {
  min-width: 24px;
  margin: 0 3px;
  color: #181d1f;
  color: var(
    --ag-secondary-foreground-color,
    var(--ag-foreground-color, #181d1f)
  );
}
.ag-theme-modes .ag-column-drop-cell-drag-handle {
  margin-left: 12px;
}
.ag-theme-modes .ag-column-drop-cell-ghost {
  opacity: 0.5;
}
.ag-theme-modes .ag-column-drop-horizontal {
  background-color: #f8f8f8;
  background-color: var(--ag-control-panel-background-color, #f8f8f8);
  color: #181d1f;
  color: var(
    --ag-secondary-foreground-color,
    var(--ag-foreground-color, #181d1f)
  );
  height: 42px;
  border-bottom: solid 1px;
  border-bottom-color: #babfc7;
  border-bottom-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-ltr .ag-column-drop-horizontal {
  padding-left: 12px;
}

.ag-theme-modes .ag-rtl .ag-column-drop-horizontal {
  padding-right: 12px;
}

.ag-theme-modes .ag-ltr .ag-column-drop-horizontal-half-width:not(:last-child) {
  border-right: solid 1px;
  border-right-color: #babfc7;
  border-right-color: var(--ag-border-color, #babfc7);
}

.ag-theme-modes .ag-rtl .ag-column-drop-horizontal-half-width:not(:last-child) {
  border-left: solid 1px;
  border-left-color: #babfc7;
  border-left-color: var(--ag-border-color, #babfc7);
}

.ag-theme-modes .ag-column-drop-horizontal-cell-separator {
  margin: 0 6px;
  color: #181d1f;
  color: var(
    --ag-secondary-foreground-color,
    var(--ag-foreground-color, #181d1f)
  );
}
.ag-theme-modes .ag-column-drop-horizontal-empty-message {
  color: rgba(24, 29, 31, 0.5);
  color: var(--ag-disabled-foreground-color, rgba(24, 29, 31, 0.5));
}
.ag-theme-modes .ag-ltr .ag-column-drop-horizontal-icon {
  margin-right: 18px;
}

.ag-theme-modes .ag-rtl .ag-column-drop-horizontal-icon {
  margin-left: 18px;
}

.ag-theme-modes .ag-column-drop-vertical-list {
  padding-bottom: 6px;
  padding-right: 6px;
  padding-left: 6px;
}
.ag-theme-modes .ag-column-drop-vertical-cell {
  margin-top: 6px;
}
.ag-theme-modes .ag-column-drop-vertical {
  min-height: 50px;
  border-bottom: solid 1px;
  border-bottom-color: #dde2eb;
  border-bottom-color: var(--ag-secondary-border-color, #dde2eb);
}
.ag-theme-modes .ag-column-drop-vertical.ag-last-column-drop {
  border-bottom: none;
}
.ag-theme-modes .ag-column-drop-vertical-icon {
  margin-left: 6px;
  margin-right: 6px;
}
.ag-theme-modes .ag-column-drop-vertical-empty-message {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  color: rgba(24, 29, 31, 0.5);
  color: var(--ag-disabled-foreground-color, rgba(24, 29, 31, 0.5));
  margin-top: 6px;
}
.ag-theme-modes .ag-select-agg-func-popup {
  border: solid 1px;
  border-color: #babfc7;
  border-color: var(--ag-border-color, #babfc7);
  background: #fff;
  background: var(--ag-background-color, #fff);
  border-radius: 3px;
  -webkit-box-shadow: var(--mo-elevation-4);
  box-shadow: var(--mo-elevation-4);
  padding: 6px;
  background: #fff;
  background: var(--ag-background-color, #fff);
  height: 105px;
  padding: 0;
}
.ag-theme-modes .ag-select-agg-func-virtual-list-item {
  cursor: default;
  padding-left: 12px;
}
.ag-theme-modes .ag-select-agg-func-virtual-list-item:hover {
  background-color: rgba(33, 150, 243, 0.3);
  background-color: var(
    --ag-selected-row-background-color,
    rgba(33, 150, 243, 0.3)
  );
}
.ag-theme-modes .ag-keyboard-focus .ag-select-agg-func-virtual-list-item:focus {
  outline: none;
}
.ag-theme-modes
  .ag-keyboard-focus
  .ag-select-agg-func-virtual-list-item:focus::after {
  content: "";
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 1px;
  left: 1px;
  display: block;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border: 1px solid;
  border-color: rgba(33, 150, 243, 0.4);
  border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes .ag-sort-indicator-container {
  display: -webkit-box;
  display: flex;
  color: var(--mo-color-primary-40, var(--mo-color-primary-7));
}
.ag-theme-modes .ag-ltr .ag-sort-indicator-icon {
  padding-left: 6px;
}

.ag-theme-modes .ag-rtl .ag-sort-indicator-icon {
  padding-right: 6px;
}

.ag-theme-modes .ag-chart-menu {
  border-radius: 3px;
  background: #fff;
  background: var(--ag-background-color, #fff);
}
.ag-theme-modes .ag-chart-menu-icon {
  opacity: 0.5;
  line-height: 24px;
  font-size: 24px;
  width: 24px;
  height: 24px;
  margin: 2px 0;
  cursor: pointer;
  border-radius: 3px;
  color: #181d1f;
  color: var(
    --ag-secondary-foreground-color,
    var(--ag-foreground-color, #181d1f)
  );
}
.ag-theme-modes .ag-chart-menu-icon:hover {
  opacity: 1;
}
.ag-theme-modes .ag-chart-mini-thumbnail {
  border: 1px solid;
  border-color: #dde2eb;
  border-color: var(--ag-secondary-border-color, #dde2eb);
  border-radius: 5px;
  margin: 5px;
}
.ag-theme-modes .ag-chart-mini-thumbnail:nth-last-child(3),
.ag-theme-modes
  .ag-chart-mini-thumbnail:nth-last-child(3)
  .ag-chart-mini-thumbnail {
  margin-left: auto;
  margin-right: auto;
}
.ag-theme-modes .ag-ltr .ag-chart-mini-thumbnail:first-child {
  margin-left: 0;
}

.ag-theme-modes .ag-rtl .ag-chart-mini-thumbnail:first-child {
  margin-right: 0;
}

.ag-theme-modes .ag-ltr .ag-chart-mini-thumbnail:last-child {
  margin-right: 0;
}

.ag-theme-modes .ag-rtl .ag-chart-mini-thumbnail:last-child {
  margin-left: 0;
}

.ag-theme-modes .ag-chart-mini-thumbnail.ag-selected {
  border-color: #2196f3;
  border-color: var(
    --ag-minichart-selected-chart-color,
    var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #2196f3))
  );
}
.ag-theme-modes .ag-chart-settings-card-item {
  background: #181d1f;
  background: var(--ag-foreground-color, #181d1f);
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
.ag-theme-modes .ag-chart-settings-card-item.ag-selected {
  background-color: #2196f3;
  background-color: var(
    --ag-minichart-selected-page-color,
    var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #2196f3))
  );
}
.ag-theme-modes .ag-chart-data-column-drag-handle {
  margin-left: 6px;
}
.ag-theme-modes .ag-charts-settings-group-title-bar,
.ag-theme-modes .ag-charts-data-group-title-bar,
.ag-theme-modes .ag-charts-format-top-level-group-title-bar {
  border-top: solid 1px;
  border-top-color: #dde2eb;
  border-top-color: var(--ag-secondary-border-color, #dde2eb);
}
.ag-theme-modes .ag-charts-settings-group-container {
  padding: 6px;
}
.ag-theme-modes .ag-charts-data-group-container {
  padding: 6px 12px;
}
.ag-theme-modes
  .ag-charts-data-group-container
  .ag-charts-data-group-item:not(.ag-charts-format-sub-level-group) {
  height: 24px;
}
.ag-theme-modes .ag-charts-data-group-container .ag-list-item-hovered::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #2196f3;
  background-color: var(--ag-range-selection-border-color, #2196f3);
}
.ag-theme-modes .ag-charts-data-group-container .ag-item-highlight-top::after {
  top: 0;
}
.ag-theme-modes
  .ag-charts-data-group-container
  .ag-item-highlight-bottom::after {
  bottom: 0;
}
.ag-theme-modes .ag-charts-format-top-level-group-container {
  margin-left: 12px;
  padding: 6px;
}
.ag-theme-modes .ag-charts-format-top-level-group-item {
  margin: 6px 0;
}
.ag-theme-modes .ag-charts-format-sub-level-group-container {
  padding: 12px 12px;
  padding-bottom: 3px;
}
.ag-theme-modes .ag-charts-format-sub-level-group-container > * {
  margin-bottom: 9px;
}
.ag-theme-modes .ag-charts-group-container.ag-group-container-horizontal {
  padding: 6px;
}
.ag-theme-modes .ag-chart-data-section,
.ag-theme-modes .ag-chart-format-section {
  display: -webkit-box;
  display: flex;
  margin: 0;
}
.ag-theme-modes .ag-chart-menu-panel {
  background-color: #f8f8f8;
  background-color: var(--ag-control-panel-background-color, #f8f8f8);
}
.ag-theme-modes .ag-ltr .ag-chart-menu-panel {
  border-left: solid 1px;
  border-left-color: #babfc7;
  border-left-color: var(--ag-border-color, #babfc7);
}

.ag-theme-modes .ag-rtl .ag-chart-menu-panel {
  border-right: solid 1px;
  border-right-color: #babfc7;
  border-right-color: var(--ag-border-color, #babfc7);
}

.ag-theme-modes .ag-date-time-list-page-title {
  -webkit-box-flex: 1;
  flex-grow: 1;
  text-align: center;
}
.ag-theme-modes .ag-date-time-list-page-column-label {
  text-align: center;
}
.ag-theme-modes .ag-date-time-list-page-entry {
  text-align: center;
}
.ag-theme-modes .ag-checkbox-input-wrapper {
  font-family: "agGridAlpine";
  font-size: 16px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 16px;
  height: 16px;
  background-color: #fff;
  background-color: var(
    --ag-checkbox-background-color,
    var(--ag-background-color, #fff)
  );
  border-radius: 3px;
  display: inline-block;
  vertical-align: middle;
  -webkit-box-flex: 0;
  flex: none;
}
.ag-theme-modes .ag-checkbox-input-wrapper input,
.ag-theme-modes .ag-checkbox-input-wrapper input {
  -webkit-appearance: none;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.ag-theme-modes .ag-checkbox-input-wrapper:focus-within,
.ag-theme-modes .ag-checkbox-input-wrapper:active {
  outline: none;
  -webkit-box-shadow: var(--mo-focus-ring);
  box-shadow: var(--mo-focus-ring);
}
.ag-theme-modes .ag-checkbox-input-wrapper.ag-disabled {
  opacity: 0.5;
}
.ag-theme-modes .ag-checkbox-input-wrapper::after {
  content: "\\f108";
  color: #999;
  color: var(--ag-checkbox-unchecked-color, #999);
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.ag-theme-modes .ag-checkbox-input-wrapper.ag-checked::after {
  content: "\\f106";
  color: #2196f3;
  color: var(
    --ag-checkbox-checked-color,
    var(--ag-alpine-active-color, #2196f3)
  );
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.ag-theme-modes .ag-checkbox-input-wrapper.ag-indeterminate::after {
  content: "\\f107";
  color: #999;
  color: var(
    --ag-checkbox-indeterminate-color,
    var(--ag-checkbox-unchecked-color, #999)
  );
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.ag-theme-modes .ag-toggle-button-input-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 28px;
  height: 18px;
  background-color: #999;
  background-color: var(
    --ag-toggle-button-off-background-color,
    var(--ag-checkbox-unchecked-color, #999)
  );
  border-radius: 9px;
  position: relative;
  -webkit-box-flex: 0;
  flex: none;
  border: 1px solid;
  border-color: #999;
  border-color: var(
    --ag-toggle-button-off-border-color,
    var(--ag-checkbox-unchecked-color, #999)
  );
}
.ag-theme-modes .ag-toggle-button-input-wrapper input {
  opacity: 0;
  height: 100%;
  width: 100%;
}
.ag-theme-modes .ag-toggle-button-input-wrapper:focus-within {
  outline: none;
  -webkit-box-shadow: var(--mo-focus-ring);
  box-shadow: var(--mo-focus-ring);
}
.ag-theme-modes .ag-toggle-button-input-wrapper.ag-disabled {
  opacity: 0.5;
}
.ag-theme-modes .ag-toggle-button-input-wrapper.ag-checked {
  background-color: #2196f3;
  background-color: var(
    --ag-toggle-button-on-background-color,
    var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #2196f3))
  );
  border-color: #2196f3;
  border-color: var(
    --ag-toggle-button-on-border-color,
    var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #2196f3))
  );
}
.ag-theme-modes .ag-toggle-button-input-wrapper::before {
  content: " ";
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 18px;
  width: 18px;
  background-color: #fff;
  background-color: var(
    --ag-toggle-button-switch-background-color,
    var(--ag-background-color, #fff)
  );
  border-radius: 9px;
  -webkit-transition: left 100ms;
  transition: left 100ms;
  border: 1px solid;
  border-color: #999;
  border-color: var(
    --ag-toggle-button-switch-border-color,
    var(
      --ag-toggle-button-off-border-color,
      var(--ag-checkbox-unchecked-color, #999)
    )
  );
}
.ag-theme-modes .ag-toggle-button-input-wrapper.ag-checked::before {
  left: calc(100% - 18px);
  border-color: #2196f3;
  border-color: var(
    --ag-toggle-button-on-border-color,
    var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #2196f3))
  );
}
.ag-theme-modes .ag-radio-button-input-wrapper {
  font-family: "agGridAlpine";
  font-size: 16px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 16px;
  height: 16px;
  background-color: #fff;
  background-color: var(
    --ag-checkbox-background-color,
    var(--ag-background-color, #fff)
  );
  border-radius: 3px;
  display: inline-block;
  vertical-align: middle;
  -webkit-box-flex: 0;
  flex: none;
  border-radius: 16px;
}
.ag-theme-modes .ag-radio-button-input-wrapper input,
.ag-theme-modes .ag-radio-button-input-wrapper input {
  -webkit-appearance: none;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.ag-theme-modes .ag-radio-button-input-wrapper:focus-within,
.ag-theme-modes .ag-radio-button-input-wrapper:active {
  outline: none;
  -webkit-box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
  box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
}
.ag-theme-modes .ag-radio-button-input-wrapper.ag-disabled {
  opacity: 0.5;
}
.ag-theme-modes .ag-radio-button-input-wrapper::after {
  content: "\\f126";
  color: #999;
  color: var(--ag-checkbox-unchecked-color, #999);
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.ag-theme-modes .ag-radio-button-input-wrapper.ag-checked::after {
  content: "\\f127";
  color: #2196f3;
  color: var(
    --ag-checkbox-checked-color,
    var(--ag-alpine-active-color, #2196f3)
  );
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.ag-theme-modes input[class^="ag-"][type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  background: none;
  overflow: visible;
}
.ag-theme-modes
  input[class^="ag-"][type="range"]::-webkit-slider-runnable-track {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 3px;
  background-color: #babfc7;
  background-color: var(--ag-border-color, #babfc7);
  border-radius: 3px;
  border-radius: 3px;
}
.ag-theme-modes input[class^="ag-"][type="range"]::-moz-range-track {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 3px;
  background-color: #babfc7;
  background-color: var(--ag-border-color, #babfc7);
  border-radius: 3px;
  border-radius: 3px;
}
.ag-theme-modes input[class^="ag-"][type="range"]::-ms-track {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 3px;
  background-color: #babfc7;
  background-color: var(--ag-border-color, #babfc7);
  border-radius: 3px;
  border-radius: 3px;
  color: transparent;
  width: calc(100% - 2px);
}
.ag-theme-modes input[class^="ag-"][type="range"]::-webkit-slider-thumb {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
  border: 1px solid;
  border-color: #999;
  border-color: var(--ag-checkbox-unchecked-color, #999);
  border-radius: 16px;
  -webkit-transform: translateY(-6.5px);
  transform: translateY(-6.5px);
}
.ag-theme-modes input[class^="ag-"][type="range"]::-ms-thumb {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
  border: 1px solid;
  border-color: #999;
  border-color: var(--ag-checkbox-unchecked-color, #999);
  border-radius: 16px;
}
.ag-theme-modes input[class^="ag-"][type="range"]::-moz-ag-range-thumb {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
  border: 1px solid;
  border-color: #999;
  border-color: var(--ag-checkbox-unchecked-color, #999);
  border-radius: 16px;
}
.ag-theme-modes input[class^="ag-"][type="range"]:focus {
  outline: none;
}
.ag-theme-modes input[class^="ag-"][type="range"]:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
  box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
  border-color: #2196f3;
  border-color: var(
    --ag-checkbox-checked-color,
    var(--ag-alpine-active-color, #2196f3)
  );
}
.ag-theme-modes input[class^="ag-"][type="range"]:focus::-ms-thumb {
  box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
  border-color: #2196f3;
  border-color: var(
    --ag-checkbox-checked-color,
    var(--ag-alpine-active-color, #2196f3)
  );
}
.ag-theme-modes input[class^="ag-"][type="range"]:focus::-moz-ag-range-thumb {
  box-shadow: 0 0 2px 0.1rem rgba(33, 150, 243, 0.4);
  border-color: #2196f3;
  border-color: var(
    --ag-checkbox-checked-color,
    var(--ag-alpine-active-color, #2196f3)
  );
}
.ag-theme-modes
  input[class^="ag-"][type="range"]:active::-webkit-slider-runnable-track {
  background-color: rgba(33, 150, 243, 0.4);
  background-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes input[class^="ag-"][type="range"]:active::-moz-ag-range-track {
  background-color: rgba(33, 150, 243, 0.4);
  background-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes input[class^="ag-"][type="range"]:active::-ms-track {
  background-color: rgba(33, 150, 243, 0.4);
  background-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
.ag-theme-modes input[class^="ag-"][type="range"]:disabled {
  opacity: 0.5;
}
.ag-theme-modes .ag-filter-toolpanel-header,
.ag-theme-modes .ag-filter-toolpanel-search,
.ag-theme-modes .ag-status-bar,
.ag-theme-modes .ag-header-row,
.ag-theme-modes .ag-panel-title-bar-title,
.ag-theme-modes .ag-multi-filter-group-title-bar {
  font-weight: 700;
  color: #181d1f;
  color: var(
    --ag-header-foreground-color,
    var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f))
  );
}
.ag-theme-modes .ag-rtl .ag-pinned-left-header .ag-header-row::before,
.ag-theme-modes .ag-ltr .ag-pinned-right-header .ag-header-row::after {
  content: "";
  position: absolute;
  height: calc(100% - 20px);
  top: 10px;
  width: 1px;
  background-color: #babfc7;
  background-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-ltr .ag-pinned-right-header .ag-header-row::after {
  right: 0;
}
.ag-theme-modes .ag-rtl .ag-pinned-left-header .ag-header-row::before {
  left: 0;
}
.ag-theme-modes .ag-row {
  font-size: 16px;
}
.ag-theme-modes input[class^="ag-"]:not([type]),
.ag-theme-modes input[class^="ag-"][type="text"],
.ag-theme-modes input[class^="ag-"][type="number"],
.ag-theme-modes input[class^="ag-"][type="tel"],
.ag-theme-modes input[class^="ag-"][type="date"],
.ag-theme-modes input[class^="ag-"][type="datetime-local"],
.ag-theme-modes textarea[class^="ag-"] {
  min-height: 24px;
  border-radius: 3px;
}
.ag-theme-modes .ag-ltr input[class^="ag-"]:not([type]),
.ag-theme-modes .ag-ltr input[class^="ag-"][type="text"],
.ag-theme-modes .ag-ltr input[class^="ag-"][type="number"],
.ag-theme-modes .ag-ltr input[class^="ag-"][type="tel"],
.ag-theme-modes .ag-ltr input[class^="ag-"][type="date"],
.ag-theme-modes .ag-ltr input[class^="ag-"][type="datetime-local"],
.ag-theme-modes .ag-ltr textarea[class^="ag-"] {
  padding-left: 6px;
}

.ag-theme-modes .ag-rtl input[class^="ag-"]:not([type]),
.ag-theme-modes .ag-rtl input[class^="ag-"][type="text"],
.ag-theme-modes .ag-rtl input[class^="ag-"][type="number"],
.ag-theme-modes .ag-rtl input[class^="ag-"][type="tel"],
.ag-theme-modes .ag-rtl input[class^="ag-"][type="date"],
.ag-theme-modes .ag-rtl input[class^="ag-"][type="datetime-local"],
.ag-theme-modes .ag-rtl textarea[class^="ag-"] {
  padding-right: 6px;
}

.ag-theme-modes .ag-tab {
  padding: 9px;
  -webkit-transition: color 0.4s;
  transition: color 0.4s;
}
.ag-theme-modes .ag-tab-selected {
  color: #2196f3;
  color: var(--ag-alpine-active-color, #2196f3);
}
.ag-theme-modes .ag-menu {
  background-color: #f8f8f8;
  background-color: var(--ag-control-panel-background-color, #f8f8f8);
}
.ag-theme-modes .ag-menu-header {
  background-color: #f8f8f8;
  background-color: var(--ag-control-panel-background-color, #f8f8f8);
  padding-top: 1px;
}
.ag-theme-modes .ag-tabs-header {
  border-bottom: solid 1px;
  border-bottom-color: #babfc7;
  border-bottom-color: var(--ag-border-color, #babfc7);
}
.ag-theme-modes .ag-charts-settings-group-title-bar,
.ag-theme-modes .ag-charts-data-group-title-bar,
.ag-theme-modes .ag-charts-format-top-level-group-title-bar {
  padding: 6px 12px;
  line-height: 20px;
}
.ag-theme-modes .ag-chart-mini-thumbnail {
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
}
.ag-theme-modes .ag-chart-settings-nav-bar {
  border-top: solid 1px;
  border-top-color: #dde2eb;
  border-top-color: var(--ag-secondary-border-color, #dde2eb);
}
.ag-theme-modes .ag-ltr .ag-group-title-bar-icon {
  margin-right: 6px;
}

.ag-theme-modes .ag-rtl .ag-group-title-bar-icon {
  margin-left: 6px;
}

.ag-theme-modes .ag-charts-format-top-level-group-toolbar {
  margin-top: 6px;
}
.ag-theme-modes .ag-ltr .ag-charts-format-top-level-group-toolbar {
  padding-left: 20px;
}

.ag-theme-modes .ag-rtl .ag-charts-format-top-level-group-toolbar {
  padding-right: 20px;
}

.ag-theme-modes .ag-charts-format-sub-level-group {
  border-left: dashed 1px;
  border-left-color: #babfc7;
  border-left-color: var(--ag-border-color, #babfc7);
  padding-left: 6px;
  margin-bottom: 12px;
}
.ag-theme-modes .ag-charts-format-sub-level-group-title-bar {
  padding-top: 0;
  padding-bottom: 0;
  background: none;
  font-weight: 700;
}
.ag-theme-modes .ag-charts-format-sub-level-group-container {
  padding-bottom: 0;
}
.ag-theme-modes .ag-charts-format-sub-level-group-item:last-child {
  margin-bottom: 0;
}
.ag-theme-modes .ag-dnd-ghost {
  font-size: 12px;
  font-weight: 700;
}
.ag-theme-modes .ag-side-buttons {
  width: 30px;
}
.ag-theme-modes .ag-standard-button {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 3px;
  border: 1px solid;
  border-color: #2196f3;
  border-color: var(--ag-alpine-active-color, #2196f3);
  color: #2196f3;
  color: var(--ag-alpine-active-color, #2196f3);
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
  font-weight: 600;
  padding: 6px 12px;
}
.ag-theme-modes .ag-standard-button:hover {
  border-color: #2196f3;
  border-color: var(--ag-alpine-active-color, #2196f3);
  background-color: rgba(33, 150, 243, 0.1);
  background-color: var(--ag-row-hover-color, rgba(33, 150, 243, 0.1));
}
.ag-theme-modes .ag-standard-button:active {
  border-color: #2196f3;
  border-color: var(--ag-alpine-active-color, #2196f3);
  background-color: #2196f3;
  background-color: var(--ag-alpine-active-color, #2196f3);
  color: #fff;
  color: var(--ag-background-color, #fff);
}
.ag-theme-modes .ag-standard-button:disabled {
  color: rgba(24, 29, 31, 0.5);
  color: var(--ag-disabled-foreground-color, rgba(24, 29, 31, 0.5));
  background-color: #f1f2f4;
  background-color: var(--ag-input-disabled-background-color, #f1f2f4);
  border-color: rgba(186, 191, 199, 0.3);
  border-color: var(--ag-input-disabled-border-color, rgba(186, 191, 199, 0.3));
}
.ag-theme-modes .ag-column-drop-vertical {
  min-height: 75px;
}
.ag-theme-modes .ag-column-drop-vertical-title-bar {
  padding: 12px;
  padding-bottom: 0px;
}
.ag-theme-modes .ag-column-drop-vertical-empty-message {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border: dashed 1px;
  border-color: #babfc7;
  border-color: var(--ag-border-color, #babfc7);
  margin: 12px;
  padding: 12px;
}
.ag-theme-modes .ag-column-drop-empty-message {
  color: #181d1f;
  color: var(--ag-foreground-color, #181d1f);
  opacity: 0.75;
}
.ag-theme-modes .ag-status-bar {
  font-weight: normal;
}
.ag-theme-modes .ag-status-name-value-value {
  font-weight: 700;
}
.ag-theme-modes .ag-paging-number,
.ag-theme-modes .ag-paging-row-summary-panel-number {
  font-weight: 700;
}
.ag-theme-modes .ag-column-drop-cell-button {
  opacity: 0.5;
}
.ag-theme-modes .ag-column-drop-cell-button:hover {
  opacity: 0.75;
}
.ag-theme-modes .ag-header-cell-menu-button:hover,
.ag-theme-modes .ag-side-button-button:hover,
.ag-theme-modes .ag-tab:hover,
.ag-theme-modes .ag-panel-title-bar-button:hover,
.ag-theme-modes .ag-header-expand-icon:hover,
.ag-theme-modes .ag-column-group-icons:hover,
.ag-theme-modes .ag-group-expanded .ag-icon:hover,
.ag-theme-modes .ag-group-contracted .ag-icon:hover,
.ag-theme-modes .ag-chart-settings-prev:hover,
.ag-theme-modes .ag-chart-settings-next:hover,
.ag-theme-modes .ag-group-title-bar-icon:hover,
.ag-theme-modes .ag-column-select-header-icon:hover,
.ag-theme-modes .ag-floating-filter-button-button:hover,
.ag-theme-modes .ag-filter-toolpanel-expand:hover,
.ag-theme-modes .ag-chart-menu-icon:hover {
  color: #2196f3;
  color: var(--ag-alpine-active-color, #2196f3);
}
.ag-theme-modes .ag-chart-settings-card-item.ag-not-selected:hover {
  opacity: 0.35;
}
.ag-theme-modes .ag-ltr .ag-panel-title-bar-button {
  margin-left: 12px;
  margin-right: 6px;
}

.ag-theme-modes .ag-rtl .ag-panel-title-bar-button {
  margin-right: 12px;
  margin-left: 6px;
}

.ag-theme-modes .ag-filter-toolpanel-group-container {
  padding-left: 6px;
}
.ag-theme-modes .ag-filter-toolpanel-instance-filter {
  border: none;
  background-color: #f8f8f8;
  background-color: var(--ag-control-panel-background-color, #f8f8f8);
  border-left: dashed 1px;
  border-left-color: #babfc7;
  border-left-color: var(--ag-border-color, #babfc7);
  margin-left: 8px;
  padding-left: 8px;
  margin-right: 12px;
}
.ag-theme-modes .ag-set-filter-list {
  padding-top: 3px;
  padding-bottom: 3px;
}
.ag-theme-modes .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-modes .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-modes .ag-layout-print .ag-center-cols-clipper,
.ag-theme-modes .ag-layout-print .ag-center-cols-container {
  min-height: 150px;
}
.ag-theme-modes .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
  padding-top: 60px;
}
.ag-theme-modes .ag-date-time-list-page-entry-is-current {
  background-color: #2196f3;
  background-color: var(--ag-alpine-active-color, #2196f3);
}
