@use "@metsooutotec/modes-react-components/dist/assets/utils" as *;
//
// Common styling for table components.
// Styles taken from MOTable styling.
//
// MOTable shadow-root prevents us from accessing table components inside MOTable.
// use this mixin when complex tables with lots of interactable elements are needed.
//

.moTableContainer {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.moTable {
  border-collapse: collapse;
  position: relative;
  font-size: var(--mo-font-size-medium);
  background-color: var(--mo-color-primary-100);
  color: var(--mo-color-primary-7);
  font-family: var(--mo-font-sans);
  width: 100%;

  @media (max-width: 576px) {
    overflow-x: scroll;
  }

  thead tr {
    color: var(--mo-color-primary-7);
    font-family: var(--mo-font-sans-regular);
    font-weight: 400;
    text-align: left;
    box-shadow: inset 0 -1px 0 var(--mo-color-primary-7);
  }

  th,
  td {
    padding: var(--mo-spacing-2x-small) var(--mo-spacing-x-small);
  }

  tbody tr {
    box-shadow: inset 0 -1px 0 var(--mo-color-secondary-70);
    line-height: var(--mo-line-height-normal);
  }
}

@mixin table {
  @extend .moTable;
}

@mixin table-container {
  @extend .moTableContainer;
}
