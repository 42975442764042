.errorText {
  color: var(--mo-color-status-alert);
}

.inputBox {
  position: relative;
}

.tooltip {
  position: absolute;
  right: 0;
}
