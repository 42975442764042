.header {
  text-align: center;
  margin-bottom: var(mo-spacing-x-large);
  color: var(mo-color-brand-black);
  font-size: var(mo-font-size-2x-large);
  font-weight: var(mo-font-weight-normal);
}

.buttonsBox {
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  mo-button.buttons::part(base) {
    height: 48px;
  }
}
