@use "@metsooutotec/modes-react-components/dist/assets/utils" as *;

.input-label-text {
  font-size: var(--mo-input-label-font-size-small);
  color: var(--mo-color-body-text);
  font-family: var(--mo-font-sans-regular);
  font-weight: var(--mo-font-weight-normal);
}

.input-label-required-text {
  font-size: var(--mo-input-label-font-size-small);
  color: var(--mo-input-color-required);
}

.input-label {
  @extend .input-label-text;
  display: inline-block;
  margin-bottom: var(--mo-spacing-3x-small);
}

.input-label-component {
  display: flex;
  flex-direction: row;
  gap: var(--mo-spacing-2x-small);
  color: var(--mo-color-primary-7);
  align-items: flex-end;

  label {
    @extend .input-label;
  }

  span.required {
    @extend .input-label-required-text;
    margin-bottom: var(--mo-spacing-3x-small);
    margin-block-end: var(--mo-spacing-3x-small);
  }
}

.align-select-input-label {
  @extend .input-label;
  // fix to align modes variable selector and DSUI input labels
  margin-bottom: 0 !important;
  line-height: v(size-m);
}

.input-error {
  color: v(color-themed-status-alert-darker);
  font-size: v(font-size-s);
  margin-top: v(size-xs);
  font-weight: 300;
}

@mixin input-label-text {
  @extend .input-label-text;
}

@mixin input-label-required-text {
  @extend .input-label-required-text;
}

@mixin input-label {
  @extend .input-label;
}

@mixin input-label-component {
  @extend .input-label-component;
}

@mixin input-error {
  @extend .input-error;
}

@mixin input-field {
  label {
    @extend .input-label;
  }
}

@mixin align-selector-input-field {
  label {
    @extend .align-select-input-label;
  }
}
