@use "@metsooutotec/modes-react-components/dist/assets/utils" as *;

.modalHeader {
  display: flex;
  justify-content: space-between;
}

.formBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.dataInput {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: end;

  mo-input {
    flex: 1;
  }
}
