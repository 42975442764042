@use "~@metsooutotec/modes-react-components/dist/assets/utils" as *;

.formStyles {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.twoInputsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--mo-spacing-x-small);
}

.inputWrapper {
  flex: 1;
}

.inputWrapper {
  flex: 1;
}

.dialogFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.dialogHeader {
  font-family: var(font-family-headings, "GT Eesti Text");
  font-size: var(font-size-2xl, 24px);
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 125% */
  border-bottom: 2px solid #d9d9d9;
  padding-bottom: 10px;
}

.twoInputsWrapper {
  display: flex;
  flex-direction: row;
}

mo-input.valueInput::part(base) {
  flex: 1;
}

.durationWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-end;
}

.errorText {
  color: var(--mo-input-error-color);
  font-size: var(--mo-input-help-text-font-size-medium);
}

.icon {
  margin-bottom: 8px;
}
