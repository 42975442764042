.organizationResourcesPage {
  display: flex;
  flex-direction: column;
}

.title {
  margin-top: var(--mo-spacing-3x-large) !important;
  font-size: var(--mo-font-size-3x-large);
  font-weight: var(--mo-font-weight-normal);
  text-align: center;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: var(--mo-spacing-3x-large);
}

.rightButtonsBox {
  display: flex;
  justify-content: end;
  gap: 30px;
}

.createNewOrganizationBox {
  display: flex;
  justify-content: center;
  margin-top: 94px;
}

.noResources {
  margin-top: 156px;
  color: var(-mo-color-secondary-secondary-80);
  text-align: center;
  font-size: var(--mo-font-size-medium);
  font-weight: var(--mo-font-weight-normal);
}

mo-button.deletebutton::part(base) {
  border-color: var(--mo-color-status-alert);
  color: var(--mo-color-status-alert);
  & > img {
    margin-top: 20px;
  }
}

.buttonIcon {
  margin-right: 8px;
}

.customCell {
  display: flex;
  height: 100%;
  align-items: center;
  align-content: center;
}

.tableButtonGroup {
  display: flex;
  flex-direction: row;
  gap: var(--mo-spacing-x-small);
}

.icon {
  color: var(--mo-color-primary-7);
}

mo-list-item::part(base) {
  display: grid;
  grid-template-columns: 1fr auto;
}

.disabled {
  opacity: 50%;
  pointer-events: none;
}
