@use "@metsooutotec/modes-react-components/dist/assets/utils" as *;

.organizationPage {
  display: flex;
  flex-direction: column;
}

.title {
  margin-top: var(--mo-spacing-3x-large) !important;
  font-size: v(mo-font-size-3x-large);
  font-weight: v(mo-font-weight-normal);
  text-align: center;
}

.deleteOrganizationBox {
  margin-top: var(--mo-spacing-3x-large);
  display: flex;
  justify-content: end;
}

.createNewOrganizationBox {
  display: flex;
  justify-content: center;
  margin-top: 94px;
}

.noOrganization {
  margin-top: 156px;
  color: v(mo-color-secondary-secondary-80, #ccc);
  text-align: center;
  font-size: v(mo-font-size-medium);
  font-weight: v(mo-font-weight-normal);
}

mo-button.deletebutton::part(base) {
  border-color: v(mo-color-status-alert);
  color: v(mo-color-status-alert);
}

.buttonIcon {
  margin-right: 8px;
}

.customCell {
  display: flex;
  height: 100%;
  align-items: center;
  align-content: center;
}

.disabled {
  opacity: 50%;
  pointer-events: none;
}