@use "../../styles//mixin.scss" as mixin;

.main {
  height: 100%;
  max-height: calc(95vh);
  padding: 0 var(--mo-spacing-3x-large);
  background-repeat: no-repeat;
  background-image: url("../../assets/mo-pattern-landing-page.svg");
  background-position: bottom;
  background-size: contain;
}
