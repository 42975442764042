// Import DSUI styles
@use "@metsooutotec/modes-react-components/dist/assets/utils" as *;
@import "@metsooutotec/modes-react-components/dist/assets";

// Import Modes UI styles
@import "@metsooutotec/modes-web-components/dist/themes/light.css";

#root {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.ui.attached.tabular.menu {
  border-bottom: 1px solid v(color-secondary-90);
  .item {
    text-transform: uppercase;
    color: v(color-secondary-30);
    border: none;
    padding: v(spacing-supersquished-inside-s);
    background: transparent;
    margin-top: 2px;
    &.active {
      font-weight: 500;
      color: v(color-brand-black);
      border: none;
      border-bottom: 2px solid v(color-brand-black);
    }
    &:hover {
      color: v(color-link-text-hover);
    }
    &:first-child {
      margin-left: v(size-m);
    }
  }
}
.ui.segment.active.tab {
  margin-top: 0;
  border: none;
  box-shadow: none;
  height: calc(100% - 40px);
  padding: 0;
  position: relative;
}

.ui.selection.dropdown {
  padding: v(spacing-inside-s);
  color: #58595b;
  min-height: 32px;
}

.ui.visible.uncover.sidebar {
  overflow: visible !important;
}

.modal h3,
.modal h4,
.modal h5 {
  text-transform: uppercase;
  padding-bottom: v(size-m);
}

.mo-toast-stack {
  top: 175px;
  right: 0;
}

$caseColors: #56b5fc, #afa759, #007c9c, #75b843;
$variableColors: #5d987c, #cc7600, #144b82, #0faab1;
