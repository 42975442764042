@use "~@metsooutotec/modes-react-components/dist/assets/utils" as *;

.buttonsGroup {
  display: flex;
  gap: var(--size-m);
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: var(--size-m);
}
