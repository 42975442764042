@use "@metsooutotec/modes-react-components/dist/assets/utils" as *;

.loginPageContainer {
  display: grid;
  height: 100vh;
  width: 100vw;
}

.loginPageWrapper {
  height: 100vh;
  width: 100vw;
  display: grid;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/geminex_login_page_background.jpeg);
  background-size: cover;
  background-position: center;
}

.loginContainer {
  width: 420px;
  height: 360px;
  background-color: var(--color-brand-white);
  color: var(--color-brand-black);
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "app-title-container"
    "login-actions-container";
}

.appTitleContainer {
  grid-area: app-title-container;
  display: grid;
  text-align: center;
  grid-gap: 44px;
  margin-top: 80px;
}

.appTitleContainer > h1 {
  margin-bottom: 0;
}

.appTitleContainer > h2 {
  margin-top: 0;
}

.logoContainer {
  position: relative;
  width: 116px;
  height: 32px;
  margin: 0 auto;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.secondaryLogo {
  font-size: 36px;
  line-height: 45px;
  padding-left: 12px;
}

.loginActionContainer {
  grid-area: login-actions-container;
  display: grid;
  align-self: stretch;
  justify-content: center;
  align-items: center;
}

mo-button.loginButton {
  height: v(size-xxl);
}
