@use "~@metsooutotec/modes-react-components/dist/assets/utils" as *;

.formStyles {
  display: flex;
  flex-direction: column;
  gap: var(--mo-spacing-small);
}

.twoInputsWrapper {
  display: flex;
  flex-direction: row;
  gap: var(--mo-spacing-x-small);
}

.inputWrapper {
  flex: 1;
}

.durationWrapper {
  display: flex;
  flex-direction: row;
  gap: var(--mo-spacing-small);
  align-items: flex-end;
}

.icon {
  margin-bottom: var(--mo-spacing-x-small);
}

.dialogFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.errorText {
  color: var(--mo-input-error-color);
  font-size: var(--mo-input-help-text-font-size-medium);
}
