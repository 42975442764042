.dialog_width_large {
  --width: 896px !important;
}

.dialog_width_medium {
  --width: 512px !important;
}

.dialog_width_small {
  --width: 364px;
}
