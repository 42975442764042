.filterWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--mo-color-neutral-0);
  gap: var(--mo-spacing-x-small);
  padding: var(--mo-spacing-x-small);
  max-width: 215px;
}

.optionStyle {
  text-transform: lowercase;
}
