.variableHeader {
  display: flex;
  align-items: center;
  font-size: var(--mo-font-size-large);
  padding: v(size-s);
  border-bottom: 1px solid var(--mo-color-neutral-20);
}

.headerButton {
  font-size: 1.5rem;
}

.variablePageContent {
  padding: var(--size-s) var(--size-m);
}

.tabPanel {
  overflow-y: hidden;
}

mo-tab-group.tabGroup::part(base) {
  display: flex;
  flex-direction: column;
}

mo-tab-group.tabGroup::part(body) {
  display: block;
}
