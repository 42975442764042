.dataInput {
  display: flex;

  flex-direction: row;
  gap: var(--mo-spacing-small);
  align-items: end;

  mo-input {
    flex: 1;
  }
}

.skeleton {
  margin-top: var(--mo-spacing-large);
  width:  95%;
  height: var(--mo-spacing-large);
}
