@use "~@metsooutotec/modes-react-components/dist/assets/utils" as *;

.dialogFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.dialogHeader {
  font-family: var(font-family-headings, "GT Eesti Text");
  font-size: var(font-size-2xl, 24px);
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 125% */
  border-bottom: 2px solid #d9d9d9;
  padding-bottom: 10px;
}
