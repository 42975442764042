.filterWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--mo-color-neutral-0);
  max-width: 216px;

  & > input[type="checkbox"] {
    display: none;
  }
}

.customLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkboxInput {
  display: none;
}

.customCheckbox {
  padding: var(--mo-spacing-x-small);
  width: 100%;
  display: inline-block;
  cursor: pointer;
  font-size: var(--mo-font-size-medium);
}

.checkboxInput:checked + .customCheckbox {
  background-color: var(--mo-color-neutral-30);
  color: white;
}
