@use "@metsooutotec/modes-react-components/dist/assets/utils" as *;

$EMAIL_LIST_HEIGHT: 224px;

.header {
  text-align: center;
  margin-bottom: v(mo-spacing-x-large);
  color: v(mo-color-brand-black);
  font-size: v(mo-font-size-2x-large);
  font-weight: v(mo-font-weight-normal);
}

.formBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.buttonsBox {
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  mo-button.buttons::part(base) {
    height: 48px;
  }
}

.emailsList {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: $EMAIL_LIST_HEIGHT;
  margin-top: var(--mo-spacing-x-small);

  & > p {
    cursor: pointer;
    padding: var(--mo-spacing-x-small) var(--mo-spacing-2x-small);
    margin-bottom: 0 !important;
    &:hover {
      background-color: var(--mo-color-neutral-90);
    }
  }
}
