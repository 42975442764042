$HEIGHT_TAB_CONTENT: 254px;
$HEIGHT_MAX_VARIABLE_LIST: 152px;
$HEIGHT_MAX_LIST: 222px;

.tabBody {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

.variableList {
  height: $HEIGHT_MAX_VARIABLE_LIST;
  overflow: auto;
}

.maxListSize {
  overflow-y: scroll;
  height: $HEIGHT_MAX_LIST;
}

.list {
  display: flex;
  flex-direction: column;
  margin-top: var(--mo-spacing-x-small);
  overflow-y: auto;

  & > p {
    padding: var(--mo-spacing-small);
    cursor: pointer;
    margin: 0 !important;
    border-bottom: 1px solid var(--mo-color-secondary-90);

    &:hover {
      background-color: var(--mo-color-secondary-90);
    }
  }
}

.listElement {
  display: flex;
  align-items: center;
  padding: var(--mo-spacing-2x-small);
  cursor: pointer;
  transition: background-color var(--mo-transition-fast);
}

.textarea {
  width: 100%;
  position: relative;
  font-family: var(--mo-font-sans);
  font-weight: var(--mo-input-font-weight);
  line-height: var(--mo-line-height-normal);
  letter-spacing: var(--mo-input-letter-spacing);
  vertical-align: middle;
  transition:
    var(--mo-transition-fast) color,
    var(--mo-transition-fast) border,
    var(--mo-transition-fast) box-shadow,
    var(--mo-transition-fast) background-color;
  cursor: text;
  padding: var(--mo-spacing-2x-small);
  border-radius: 0 !important;
  border-color: black;
  resize: vertical;
}

.errorTextArea {
  border-color: var(--mo-input-error-border-color);
}

.errorTextArea {
  margin-top: var(--mo-spacing-2x-small);
  color: var(--mo-input-error-color);

  font-size: var(--mo-input-help-text-font-size-medium);
}

mo-tab-group.tabGroup::part(base) {
  margin-top: var(--mo-spacing-x-small);
  display: flex;
  flex-direction: column;
}

mo-tab-group.tabGroup::part(body) {
  display: block;
  height: $HEIGHT_TAB_CONTENT;
  overflow: hidden;
}
