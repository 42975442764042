@use "@metsooutotec/modes-react-components/dist/assets/utils" as *;
@use "sass:math";
@use "sass:map";

// header bar height
$VIEW_HEADER_HEIGHT: 48px;

// offset for view title
$VIEW_TITLE_OFFSET: calc(160px - #{$VIEW_HEADER_HEIGHT});

// common styles for showing landing view with landing page background
.landingView {
  height: 100vh;
  display: flex;
  flex-direction: column;

  :global(.viewContentContainer) {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    align-items: center;
    height: calc(100vh - #{$VIEW_HEADER_HEIGHT});
    background: v(color-themed-primary-100)
      url(../assets/mo-pattern-landing-page.svg) repeat-x left bottom;
    position: relative;
    overflow-y: auto;

    :global(.content) {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        margin-top: $VIEW_TITLE_OFFSET;
        padding: v(size-s) 0 v(size-xxxl) 0;
        margin-bottom: 0;
        text-align: center;
      }
    }

    :global(.actions) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: v(size-xxxl);
      margin-bottom: v(size-m);
      gap: v(size-m);
    }
  }
}

@mixin landing-view {
  @extend .landingView;
}
