@use "@metsooutotec/modes-react-components/dist/assets/utils" as *;

$CONTENT_PREVIEW_MIN_WIDTH: 278px; // minimum preview box width
$CONTENT_PREVIEW_MIN_HEIGHT: 278px; // minimum preview box height
$CONTENT_PREVIEW_MAX_HEIGHT: 292px; // max content preview box height
$CONTENT_PREVIEW_OFFSET: 18px; // offset to aling preview with first input

$ACTION_BAR_HEIGHT: 64px; // 48px + 16px

.dialog-actions {
  padding: var(--size-m) 0 0 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-height: $ACTION_BAR_HEIGHT;

  & > button {
    height: var(--size-xxxl);
  }

  & > button:last-child {
    margin-left: v(size-l) !important;
  }
  & > button:nth-last-child(2) {
    margin-left: auto !important;
  }
  & > button:first-child:not(button:nth-last-child(2)) {
    margin-left: 0 !important;
  }
}

.dialog-content-with-preview {
  display: grid !important;
  grid-template-columns: repeat(
    auto-fit,
    minmax($CONTENT_PREVIEW_MIN_WIDTH, 1fr)
  );
  grid-template-rows: auto 1fr auto auto;
  grid-template-areas:
    "dialog-content-main dialog-content-preview"
    "dialog-content-widget dialog-content-preview"
    "dialog-content-widget-wide dialog-content-widget-wide"
    "dialog-content-footer dialog-content-footer";
  grid-column-gap: v(size-l);
  grid-row-gap: v(size-m);
  align-content: flex-start;
  margin-top: v(size-l);
}

.dialog-content-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-row-gap: v(size-m);
}

.dialog-content-columns {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: v(size-m);

  & > div {
    min-width: v(size-l);
  }
}

.dialog-content-columns-decimals {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: v(size-m);

  & > div {
    min-width: v(size-l);
  }
}

.dialog-preview-container {
  width: 100%;
  height: 100%;
  min-width: $CONTENT_PREVIEW_MIN_WIDTH;
  min-height: $CONTENT_PREVIEW_MIN_HEIGHT;
  max-height: $CONTENT_PREVIEW_MAX_HEIGHT;
  border: 1px solid v(color-themed-secondary-70);
  color: v(color-themed-secondary-70);
  overflow: hidden;
  white-space: nowrap;
}

@mixin dialog-base {
  &:global(.modal) > :global(.content) {
    line-height: v(line-height-xs);
  }

  :global(.actions) {
    @extend .dialog-actions;
  }
}

@mixin dialog-content-with-preview {
  @extend .dialog-content-with-preview;
}

@mixin dialog-actions {
  @extend .dialog-actions;
}

@mixin dialog-content-main {
  @extend .dialog-content-container;
  grid-area: dialog-content-main;
}

@mixin dialog-content-preview {
  @extend .dialog-preview-container;
  grid-area: dialog-content-preview;
  margin-top: $CONTENT_PREVIEW_OFFSET; // label height to aling the preview with first input box
}

@mixin dialog-content-widget {
  @extend .dialog-content-container;
  grid-area: dialog-content-widget;
}

@mixin dialog-content-widget-wide {
  grid-area: dialog-content-widget-wide;
}

@mixin dialog-content-footer {
  grid-area: dialog-content-footer;
}

@mixin dialog-content-columns {
  @extend .dialog-content-columns;
}

@mixin dialog-content-columns-decimals {
  @extend .dialog-content-columns-decimals;
}
