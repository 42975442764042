@use "../../styles//mixin.scss" as mixin;

.navBackButton {
  color: var(--color-themed-body-text) !important;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &::part(base) {
    color: inherit !important;
    padding-left: var(--size-xs);
    padding-right: var(--size-s);
  }

  &::part(prefix) {
    font-size: 24px;
  }
}
