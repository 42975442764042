.usersPage {
  display: flex;
  flex-direction: column;
}

.title {
  margin-top: var(--mo-spacing-3x-large) !important;
  font-size: var(--mo-font-size-3x-large);
  font-weight: var(--mo-font-weight-normal);
  text-align: center;
}

.buttonWrapper {
  display: flex;
  justify-content: end;
  margin-top: var(--mo-spacing-3x-large);
}

.icon {
  color: var(--mo-color-primary-7);
}

.buttonIcon {
  margin-right: 8px;
}

.tableButtonGroup {
  display: flex;
  flex-direction: row;
  gap: var(--mo-spacing-x-small);
}
