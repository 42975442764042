.header {
  text-align: center;
  margin-bottom: v(mo-spacing-x-large);
  color: v(mo-color-brand-black);
  font-size: v(mo-font-size-2x-large);
  font-weight: v(mo-font-weight-normal);
}

.formBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.buttonsBox {
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  mo-button.buttons::part(base) {
    height: 48px;
  }
}

.icon {
  color: black;
}

.removeButton {
  justify-self: start;
  width: 35%;

  &:hover {
    cursor: pointer;
  }
}

.projectBox {
  display: flex;
  flex-direction: column;
  gap: var(--mo-spacing-x-small);
}
