  .formStyles {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .inputWrapper {
    flex: 1;
  }
  
  .dialogHeader {
    font-family: var(--mo-font-sans);
    font-size: var(--font-size-2xl);
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 125% */
    border-bottom: 2px solid #d9d9d9;
    padding-bottom: 10px;
  }
  
  .dialogFooter {
    display: flex;
    justify-content: space-between;
    margin-top: var(--mo-spacing-medium);
  }


  .divider {
    margin-top: var(--mo-spacing-small);
  }

  .removeButton{
    width: 40px;
    display: flex;
    &::part(base) {
      color: var(--mo-color-status-alert);
      border: 2px solid var(--mo-color-status-alert);
    }
  }
